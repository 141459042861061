import { useState } from "react"
import { useLocation, useNavigate } from "react-router"
import Swal from 'sweetalert2'

import Button from '../../components/Button'
import Input from '../../components/Input'

import banner1 from '../../assets/images/banner-1.png'
import banner1Mobile from '../../assets/images/banner-1-mobile.png'

import authService from "../../services/authService"

import logo from '../../assets/images/logo.png'

export default function LembrarSenha() {
  const [dados, setDados] = useState({})
  const navigate = useNavigate()
  const { state } = useLocation()
  const { returnUrl } = state || {}

  const handleChange = (campo, valor) => {
    setDados({
      ...dados,
      [campo]: valor
    })
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()

    try {
      await authService.lembrarSenha(dados.email)
      await Swal.fire({
        text: 'Caso o e-mail informado esteja correto você receberá uma senha temporária'
      })
      navigate('/login', { state: { returnUrl } })
    } catch (ex) {
      console.log(ex)
    }
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='w-full h-[300px] hidden xl:block' style={{
        background: `url(${banner1})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className='w-full h-[300px] xl:hidden' style={{
        background: `url(${banner1Mobile})`,
        backgroundPositionX: 'center'
      }}>
      </div>

      <form onSubmit={handleSubmit}
        className="bg-white p-5 flex flex-col items-stretch gap-4 w-full text-[#1e1e1e]"
      >
        <p className="text-center text-sm my-2">Informe o e-mail utilizado no seu cadastro abaixo. Vamos enviar uma
          senha temporária para seu e-mail.</p>
        <div className='grid grid-cols-1 xl:grid-cols-2 gap-x-10 gap-y-5'>
          <div>
            <Input
              label="E-mail"
              className="w-full"
              value={dados.email || ''}
              onChange={el => handleChange('email', el.target.value)}
            />
          </div>
          <div>
            <Button
              type="submit"
              className="rounded-md bg-[#7949FF] text-white text-[16px] px-8 py-1 h-[49px] font-semibold w-full xl:w-auto xl:mt-[25px]"
            >
              Receber senha temporária
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}