import { naoImplantada } from "../infra/utils";
import Button from "./Button";

export default function ItemCaixa({ item, inline, onVerInformacoes }) {
  return (
    <div className="border border-vermelho rounded p-4 flex flex-col gap-4 justify-center items-center bg-gradient-to-b from-[#FF18BE] to-[#1e1e1e] relative py-8">
      <div className="rounded-full bg-roxo text-white w-[51px] h-[51px] absolute top-0 flex justify-center items-center text-[12px] -mt-[26px]">{item.chanceFmt}</div>
      <div className="text-center text-[20px] text-white font-bold uppercase min-h-[60px]">{item.nome}</div>
      <div><img src={item.imagem} alt="Item 1" className='w-[160px]' /></div>
      <div className="text-center text-[24px] text-white font-bold uppercase drop-shadow-md">R${item.valorFmt}</div>
      <Button
        className="bg-verde text-black rounded-md px-4 py-2 text-[12px] xl:text-[15px] bottom-0 -mb-[20px] absolute"
        onClick={() => onVerInformacoes && onVerInformacoes(item.id)}
      >
        Ver informações
      </Button>
    </div>
  )
}