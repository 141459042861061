import { useEffect, useMemo, useState } from 'react'
import iconeFechar from '../assets/images/icone-botao-fechar-branco.png'
import Loading from './Loading'

import instituicaoService from '../services/instituicaoService'
import compraService from '../services/compraService'
import { alert } from '../infra/utils'

export default function EscolherInstituicao({
  idAbertura,
  ocultarVoltar,
  onVoltar,
  onFechar,
  onDoacaoExecutada,
}) {
  const [instituicoes, setInstituicoes] = useState()

  useEffect(() => {
    instituicaoService.listar().then(setInstituicoes)
  }, [])

  const handleDoar = (idInstituicao) => {
    const instituicao = instituicoes.find(el => el.id == idInstituicao)
    try {
      compraService.doar(idAbertura, idInstituicao).then(async () => {
        await alert(`Você doou seu prêmio para ${instituicao.nome}`)
        onDoacaoExecutada && onDoacaoExecutada()
      })
    } catch (ex) {
      alert(ex.message)
    }
  }


  if (!instituicoes)
    return <Loading />

  return (
    <div className="fixed inset-0 bg-[#0007] z-40">
      <div className="xl:max-w-screen-xl mx-auto p-2 xl:p-10">
        <div className="xl:mt-[100px] p-4 xl:p-40 rounded rounded-md bg-white text-[#1E1E1E]">
          <div className='grid grid-cols-1 xl:grid-cols-5 gap-4'>
            {instituicoes.map(el => (
              <div
                className='grow h-10 bg-gradient-to-b from-[#00000010] to-[#00000040] border border-[#00000040] rounded flex justify-center items-center font-bold text-[12px] cursor-pointer'
                key={el.id}
                onClick={() => handleDoar(el.id)}
              >
                Doar para {el.nome}
              </div>
            ))}
            <div className='grow'><button onClick={onFechar} className='h-10 w-full bg-[#ED3B46] py-2 rounded-md flex flex-row gap-3 justify-center items-center font-bold text-[12px] text-white'><img src={iconeFechar} alt="Fechar" />  Fechar</button></div>
            {!ocultarVoltar && (
              <div className='grow'><button onClick={onVoltar} className='h-10 w-full bg-[#ffd121] py-2 rounded-md flex flex-row gap-3 justify-center items-center font-bold text-[12px] text-[#1e1e1e]]'>Voltar</button></div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}