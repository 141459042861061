import { useMemo } from "react"

export default function Select({
  name, value, onChange, className, placeholder, options,
  id, label, error, onFocus, onBlur, disabled, manterAparencia = false
}) {
  const finalClassName = useMemo(() => {
    let _className = className
    if (!manterAparencia) {
      _className = " rounded px-3 text-[#1e1e1e] bg-[#ECE9E9] border border-[#B8B8B8] disabled:text-[#555] h-[49px]"
      if (error) {
        _className += ` border-[#f00]`
      }
    }
    return _className
  }, [className, error, manterAparencia])

  const input = <select
    name={name}
    id={id}
    className={finalClassName}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    onFocus={onFocus}
    disabled={disabled}
  >
    {options.map(el => (
      <option key={el.value} value={el.value}>{el.label}</option>
    ))}
  </select>

  return (
    <div className={"flex flex-col gap-1 w-full"}>
      {label && <label className="text-sm" htmlFor={id}>{label}</label>}
      {input}
      {error && <div className="text-[14px] text-[#f00]">{error}</div>}
    </div>
  )
}