import { useMemo, useState } from "react";
import { alert, apenasNumeros, formatNumber, maskNumber, validate } from "../../../infra/utils";

import { useAppState } from "../../../overmind";

import iconeGlobo from '../../../assets/images/icone-globo.png'
import iconeInfo from '../../../assets/images/icone-info.png'

import AreaLogada from "..";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import creditoService from "../../../services/creditoService";
import ModalPix from "../../../components/ModalPix";
import Link from "../../../components/Link";

export default function AdicionarCredito() {
  const [dados, setDados] = useState({ valor: 0 })
  const [touched, setTouched] = useState({})
  const [errors, setErrors] = useState({})
  const [configPix, setConfigPix] = useState()
  const [tip, setTip] = useState(false)
  const { usuario } = useAppState()

  const schema = useMemo(() => {
    return [
      { campo: 'valor', required: true, type: 'number' },
    ]
  }, [])

  const handleValue = ev => {
    let _valor = apenasNumeros(ev.target.value)
    if (_valor == '') _valor = '0'
    setDados({
      valor: parseInt(_valor) / 100
    })
  }

  const handleButtonValue = valor => () => setDados({ valor })

  const handleSubmit = async ev => {
    ev.preventDefault()
    const isValid = schema.reduce((isValid, rule) => {
      if (!_validate(rule.campo, true))
        return false
      return isValid
    }, true)

    if (isValid) {
      try {
        const retorno = await creditoService.adicionar(dados.valor)
        setConfigPix(retorno)
      } catch (ex) {
        debugger
        console.log(ex)
      }
    }
  }

  const handleFecharModal = () => {
    setConfigPix()
    setDados({ valor: 0 })
  }

  const handleFocus = (campo) => {
    setTouched({
      ...touched,
      [campo]: true
    })
  }

  const _validate = (campo, ignoreTouched = false) => validate(touched, schema, dados, campo, setErrors, ignoreTouched)

  const handleBlur = (campo) => {
    _validate(campo)
  }


  return (
    <AreaLogada>
      <div className="text-[#1E1E1E]">
        <ModalPix isOpen={!!configPix} config={configPix} onClose={handleFecharModal} />
        <h1 className='text-[24px] xl:text-[48px] text-center xl:text-start font-bold mb-6'>Depositar</h1>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col-reverse xl:flex-row gap-10">
            <div className="w-full xl:w-1/2 text-center xl:text-start">
              <p className="my-3">
                Insira o valor que deseja depositar. Na próxima etapa, você será questionado sobre qual método de pagamento prefere adicionar à sua conta.
              </p>
              <p className="my-3">
                <strong className="font-bold">Importante:</strong> O dinheiro será enviado para a conta bancária conectada ao seu CPF, que será usado como
                chave PIX e registrado na sua conta SorteBox. Por favor, confirme que o seu CPF está registrado como chave PIX na sua conta bancária.
                Se a chave PIX estiver incorreta, <Link manterAparencia navigateTo="/contato" className="text-vermelho underline">por favor contate o Suporte</Link>.
              </p>
            </div>
            <div className="w-full xl:w-1/2">
              <div className="rounded-md bg-[#E3E3E3] p-4">
                <div className="flex flex-row gap-2 items-center text-[14px] font-semibold">
                  <img src={iconeGlobo} alt="Faça um depósito on-line" /> <span>Faça um depósito on-line</span>
                </div>
                <div className="border-b border-[#B8B8B8] mt-4 xl:mt-8 pb-2">
                  <div className="font-semibold text-[#9A9797] text-[10px] text-center xl:text-start">CPF</div>
                  <div className="text-[20px] font-semibold relative text-center xl:text-start">
                    {usuario.cpf}
                    <div className={"rounded-md p-2 text-white bg-[#1E1E1E7F] text-[10px] font-normal w-[120px] absolute right-0 -top-[70px] " + (tip ? '' : 'hidden')}>
                      Número de CPF informado durante o registro da conta
                    </div>
                    <img src={iconeInfo} className="hidden xl:block absolute top-0 right-0" alt="" onMouseOver={() => setTip(true)} onMouseOut={() => setTip(false)} />
                  </div>
                </div>
                <div className="flex flex-col xl:flex-row gap-2 mt-2">
                  <button type="button" onClick={handleButtonValue(50)} className="bg-white rounded-md font-[10px] h-[34px] w-full">R$50,00</button>
                  <button type="button" onClick={handleButtonValue(150)} className="bg-white rounded-md font-[10px] h-[34px] w-full">R$150,00</button>
                  <button type="button" onClick={handleButtonValue(200)} className="bg-white rounded-md font-[10px] h-[34px] w-full">R$200,00</button>
                </div>
                <div className="bg-white rounded-md p-2 mt-2 border border-[#B8B8B8]">
                  <div className="text-right font-semibold text-[10px] text-[#9A9797]">Valor a depositar</div>
                  <Input
                    id="valor"
                    value={formatNumber(dados.valor, 2)}
                    manterAparencia
                    className="text-right text-[20px]"
                    onChange={handleValue}
                    maxLength={9}
                    error={errors.valor}
                    onBlur={() => handleBlur('valor')}
                    onFocus={() => handleFocus('valor')}
                  />
                </div>
                <Button
                  type="submit"
                  className="bg-[#FF3B3B] rounded-md w-full h-[48px] text-[14px] text-white mt-2"
                >
                  DEPOSITAR
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </AreaLogada>
  )
}