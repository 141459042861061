import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import Swal from 'sweetalert2'
import { useSearchParams } from 'react-router-dom'

import Button from '../../components/Button'
import Input from '../../components/Input'
import Link from '../../components/Link'

import banner1 from '../../assets/images/banner-1.png'
import banner1Mobile from '../../assets/images/banner-1-mobile.png'

import { useActions, useAppState } from '../../overmind'
import authService from '../../services/authService'

export default function Login() {
  const [dados, setDados] = useState({ email: '', senha: '' })
  const navigate = useNavigate()
  const { state } = useLocation()
  const [searchParams] = useSearchParams()
  const { returnUrl } = state || { returnUrl: searchParams.get('returnUrl') }
  const { setUsuario, removerCarrinhoPorLoja } = useActions()
  const { usuario } = useAppState()

  useEffect(() => {
    if (usuario) {
      authService
        .me()
        .then(res => {
          if (res.senhaTemporaria) {
            navigate('/alterar-senha?returnUrl=' + (returnUrl || '/sua-conta'))
          } else {
            navigate(returnUrl || '/sua-conta')
          }
        })
        .catch()
    }
  }, [usuario, returnUrl])

  const handleCadastreSe = () => {
    navigate('/cadastro', {
      state: {
        returnUrl
      }
    })
  }

  const handleChange = (campo, valor) => {
    setDados({
      ...dados,
      [campo]: valor
    })
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()

    try {
      const authUser = await authService.login(dados.email, dados.senha)
      setUsuario(authUser)
      const me = await authService.me()
      setUsuario({ ...authUser, ...me })
      // não é necessário redirecionar pois ao atualizar usuário um effect é disparado enviando o usuário para o local correto
    } catch (ex) {
      if (ex.message === "Unauthorized") {
        Swal.fire({
          text: 'Os dados de acesso informados não estão corretos. Caso não se lembre da senha utilize o link "Esqueci a senha"'
        })
      } else {
        Swal.fire({
          text: 'Houve algum erro para verificar os dados de acesso. Por favor tente novamente mais tarde'
        })
      }
    }
  }


  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='w-full h-[300px] hidden xl:block' style={{
        background: `url(${banner1})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className='w-full h-[300px] xl:hidden' style={{
        background: `url(${banner1Mobile})`,
        backgroundPositionX: 'center'
      }}>
      </div>

      <form onSubmit={handleSubmit}
        className="bg-white p-5 flex flex-col items-stretch gap-4 w-full text-[#1e1e1e]"
      >
        <h1 className='text-[24px] xl:text-[48px] font-bold'>Identifique-se</h1>
        <div className='grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5'>
          <div>
            <Input label="E-mail" className="w-full" value={dados.email} onChange={el => handleChange('email', el.target.value)} />
          </div>
          <div>
            <Input label="Senha" type="password" className="w-full" value={dados.senha} onChange={el => handleChange('senha', el.target.value)} />
          </div>
          <div>
            <Button
              type="submit"
              className="rounded-md bg-[#7949FF] text-white text-[16px] px-8 py-1 h-[49px] font-semibold w-full xl:w-auto xl:mt-[25px]"
            >
              Entrar
            </Button>
          </div>
        </div>
        <div className='text-center w-full'>
          <Link manterAparencia className="text-[#7949FF] underline" onClick={() => navigate('/lembrar-senha', { state: { returnUrl } })}>Esqueci a senha</Link>
        </div>
        <div className='text-center w-full'>
          Não tem uma conta? <Link manterAparencia className="text-[#7949FF] underline" onClick={handleCadastreSe}>Cadastre-se</Link>
        </div>
      </form>
    </div>
  )
}