import { Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

import routes from "../routes";

import DefaultHeader from "../components/DefaultHeader";
import DefaultFooter from "../components/DefaultFooter";
import Loading from "../components/Loading";
import { useAppState } from "../overmind";

const AuthRoute = ({ children }) => {
  const { usuario } = useAppState()
  const location = useLocation()

  if (!usuario) {
    return <Navigate to="/login" />
  }

  // if (!usuario.emailConfirmado && location.pathname !== '/cadastro/confirmar-email') {
  //   return <Navigate to="/cadastro/confirmar-email" />
  // }
  
  if (usuario.senhaTemporaria && location.pathname !== '/alterar-senha') {
    return <Navigate to="/alterar-senha" />
  }

  return children
}

export default function DefaultLayout() {
  const location = useLocation()
  const [oldPathname, setOldPathname] = useState('')

  useEffect(() => {
    if (oldPathname !== location.pathname) {
      window.scrollTo(0, 0)
      setOldPathname(location.pathname)
    }
  }, [location, oldPathname])

  return (
    <div className="max-w-screen-xl mx-auto bg-layout">
      <DefaultHeader />
      <div style={{
        minHeight: 'calc(100vh - 136px - 260px - 2.5rem)'
      }}>
        <Suspense fallback={<Loading />}>
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={route.auth ? <AuthRoute><route.element /></AuthRoute> : <route.element />}
                  />
                )
              )
            })}
            <Route path="/" element={<Navigate to="home" replace />} />
          </Routes>
        </Suspense>
      </div>
      <DefaultFooter />
    </div>
  )
}