import banner1 from '../../assets/images/banner-1.png'
import banner1Mobile from '../../assets/images/banner-1-mobile.png'

export default function Collab() {
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='w-full h-[300px] hidden xl:block' style={{
        background: `url(${banner1})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className='w-full h-[300px] xl:hidden' style={{
        background: `url(${banner1Mobile})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className='bg-white p-4 xl:p-10 flex flex-col items-stretch gap-2 xl:gap-4 w-full text-[#1e1e1e]'>
      <h1 className='text-[24px] xl:text-[48px] font-semibold xl:font-bold text-center xl:text-start'>Collab</h1>
      </div>
    </div>
  )
}