import { useEffect, useMemo, useState } from "react";

import AreaLogada from "..";

import iconeBusca from '../../../assets/images/icone-busca.png'

import Select from "../../../components/Select";
import Loading from '../../../components/Loading'

import Item from "./Item";
import ItemProcessado from "./ItemProcessado";

import compraService from "../../../services/compraService";
import { formatNumber, naoImplantada } from "../../../infra/utils";
import pedidoService from "../../../services/pedidoService";
import ModalPix from "../../../components/ModalPix";

export default function MeusItens() {
  const [status, setStatus] = useState('')
  const [dados, setDados] = useState()
  const [selecionados, setSelecionados] = useState([])
  const [frete, setFrete] = useState(0)
  const [configPix, setConfigPix] = useState()

  useEffect(() => {
    carregar()
  }, [status])

  useEffect(() => {
    if (selecionados.length == 0)
      setFrete(0)
    else {
      pedidoService.calcularFrete(selecionados).then(frete => {
        setFrete(frete)
      })
    }
  }, [selecionados])

  const pendentes = useMemo(() => {
    return (dados || []).filter(el => el.status == 'pendente')
  }, [dados])

  const processados = useMemo(() => {
    return (dados || []).filter(el => el.status != 'pendente')
  }, [dados])

  const totalSelecionados = useMemo(() => {
    if (selecionados.length == 0) return '0,00'
    return formatNumber(selecionados.map(id => dados.find(el => el.id == id)).map(el => el.item.valor).reduce((acc, el) => acc + el))
  }, [selecionados])

  const carregar = () => {
    compraService.listar(status).then(setDados)
  }

  const handleReload = () => {
    carregar()
  }

  const handleChangeSelecao = (id, checked) => {
    if (checked) {
      if (!selecionados.some(_id => _id === id)) {
        setSelecionados([...selecionados, id])
      }
    } else {
      setSelecionados(selecionados.filter(_id => _id !== id))
    }
  }

  const handleSolicitarEntrega = async (idAbertura) => {
    try {
      const pedido = await pedidoService.criar(idAbertura ? [idAbertura] : [...selecionados])
      if (pedido.frete.dadosPix) {
        setConfigPix(pedido.frete.dadosPix)
      }
      setSelecionados([])
      carregar()
    } catch (ex) {
      alert(ex.message)
    }
  }


  if (!dados)
    return <Loading />

  return (
    <AreaLogada>
      <div className="text-[#1E1E1E]">
        <h1 className='text-[24px] xl:text-[48px] text-center xl:text-start font-bold mb-6'>Meus itens</h1>
        <div className="flex flex-col xl:flex-row gap-4">
          <div className="w-full xl:w-1/3">
            <div className="border border-[#B8B8B8] px-4 py-2 rounded-full flex flex-row gap-2 items-center h-[49px]">
              <img src={iconeBusca} />
              <input type="text" className="h-[45px] w-full active:border-0 outline-none" />
            </div>
          </div>
          <div className="w-full xl:w-2/3">
            <Select
              options={[
                { value: "", label: "Selecione o status" },
                { value: "pendente", label: "Não trocados" },
                { value: "trocado", label: "Trocados" },
                { value: "encomendado", label: "Encomendados" },
              ]}
              value={status}
              onChange={ev => setStatus(ev.target.value)}
            />
          </div>
        </div>

        {pendentes.length > 0 && (
          <>
            <div className="mt-10 flex flex-col gap-2">
              {pendentes.map(el => (
                <Item
                  key={el.id}
                  item={el}
                  checked={selecionados.some(id => id === el.id)}
                  onChange={checked => handleChangeSelecao(el.id, checked)}
                  onReload={handleReload}
                  onSolicitarEntrega={handleSolicitarEntrega}
                />
              ))}
            </div>
            <div className="flex flex-col xl:flex-row justify-between mt-10 border-y-8 border-[#EBEBEB] py-4 items-center">
              <div className="text-[16px] font-bold w-full xl:w-auto px-2">
                <div>Total Produtos: {selecionados.length}</div>
                <div>Total Selecionados: R${totalSelecionados}</div>
                <div>Frete: R${formatNumber(frete)}</div>
              </div>
              <div className="w-full xl:w-auto mt-4 xl:mt-0">
                <button
                  className="bg-[#81FF31] text-[#1E1E1E] rounded-md mx-auto w-full xl:w-[200px] h-[38px] font-bold text-[15px] disabled:text-[#AAA] disabled:bg-[#c3ff9f]"
                  onClick={() => handleSolicitarEntrega()}
                  disabled={selecionados.length == 0}
                >
                  Solicitar entrega
                </button>
              </div>
            </div>
          </>
        )}

        <div className="mt-10 flex flex-col gap-2">
          {processados.map(el => (
            <ItemProcessado key={el.id} item={el} />
          ))}
        </div>

      </div>
      <ModalPix isOpen={!!configPix} config={configPix} onClose={() => setConfigPix()} />
    </AreaLogada>
  )
}