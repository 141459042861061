import { useEffect, useState, useMemo, useRef, useCallback } from "react"
import ItemCaixaSorteio from "../../components/ItemCaixaSorteio"

import iconeFechar from '../../assets/images/icone-fechar.png'
import bgSelecao from '../../assets/images/bg-selecao.png'
import { naoImplantada } from "../../infra/utils"
import { useNavigate } from "react-router"

export default function Sorteando({
  item,
  sorteado,
  tentar,
  onConfirmarReceber,
  onConfirmarDoacao,
  onAbrirNovamente,
  onClose
}) {
  const [items, setItems] = useState()
  const [marginLeft, setMarginLeft] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [concluido, setConcluido] = useState(false)
  const quadroRef = useRef()
  const navigate = useNavigate()

  useEffect(() => {
    setLoaded(false)
    // setConcluido(false)
    // setMarginLeft(0)
    setMarginLeft(window.innerWidth)
    setTimeout(() => {
      setLoaded(true)
    }, 500)
  }, [])

  useEffect(() => {
    if (!item)
      return

    setItems(item
      .itens
      .sort(() => Math.round(Math.random() * 2) - 1)
      .sort(() => Math.round(Math.random() * 2) - 1)
      .sort(() => Math.round(Math.random() * 2) - 1)
    )
  }, [item])

  const itemSorteado = useMemo(() => {
    if (!sorteado || !items)
      return null

    return items.find(el => el.id == sorteado.idItemSorteado)
  }, [sorteado, items])

  const calcularMargem = useCallback((pos) => {
    if (!quadroRef.current)
      return 0

    return (-230 * pos) + 260 + quadroRef.current.offsetLeft
  }, [quadroRef.current])

  useEffect(() => {
    if (!items || !sorteado || !loaded)
      return

    let idx = -1
    idx = (8 * items.length) + items.findIndex(el => el.id == sorteado.idItemSorteado)

    const _marginLeft = calcularMargem(idx + 1)
    setMarginLeft(_marginLeft)

    setTimeout(() => {
      setConcluido(true)
    }, 10500);
  }, [items, sorteado, loaded])

  const handleAbrirOutraCaixa = () => {
    navigate('/home')
  }

  return (
    <div className="fixed inset-0 bg-[#0007]">
      <div className="max-w-screen-xl mx-auto xl:p-10">
        <div className="xl:mt-[100px] bg-[#1E1E1E] xl:border xl:border-verde py-5 xl:px-5 relative">
          <img className="absolute top-2 right-2 cursor-pointer hidden xl:block" src={iconeFechar} alt="Fechar" onClick={onClose} />
          <h1 className="text-center text-[24px] xl:text-[48px] mt-2 mb-6">Espere a roleta parar para ver o seu prêmio</h1>
          <div className="border border-y-verde border-x-0">
            <div className="relative pt-[10px] overflow-hidden h-[300px]">
              <div
                className="absolute top-0 left-[50%] -ml-[100px] w-[200px] h-[300px]"
                style={{
                  background: 'linear-gradient(180deg, rgba(82, 255, 0, 0.55) 0.5%, #8000FF 31%, #DBFF00 55%, #FF18BE 77.5%, #63FF00 97.69%)',
                  filter: 'blur(50px)'
                }}
              >

              </div>
              <div
                className={"absolute top-0 left-0 mt-3 " + (loaded && "transition-all")}
                style={{
                  marginLeft,
                  ...(loaded && {
                    transitionTimingFunction: 'ease',
                    transitionDuration: '10s'
                  })
                }}
              >
                {items && (
                  <div className="flex flex-row gap-[50px]">
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                    {items.map(el => (
                      <ItemCaixaSorteio key={el.id} item={el} />
                    ))}
                  </div>
                )}
              </div>
              <div className="absolute top-0 left-[50%] -mt-[17px] -ml-[121px]" ref={quadroRef}>
                <img src={bgSelecao} />
              </div>
            </div>
          </div>
          {concluido && (
            <div className="px-5 xl:px-0">
              <div className="mt-6 mb-2 xl:mb-6 text-verde text-[24px] xl:text-[48px] font-bold text-center">Você {tentar ? "ganharia" : "ganhou"} um {(itemSorteado?.nome || '').toUpperCase()}!</div>
              {!tentar && (
                <>
                  <div className="my-2 text-center text-[14px] xl:text-[24px]">Escolha o que deseja fazer</div>
                  <div className="grid grid-cols-2 xl:grid-cols-4 gap-2 xl:gap-4">
                    <button onClick={onConfirmarDoacao} className="h-9 xl:h-16 w-full rounded-md bg-[#00E2B9] font-bold text-[12px] xl:text-[20px] text-black">Doar para ONG</button>
                    <button onClick={onAbrirNovamente} className="h-9 xl:h-16 w-full rounded-md bg-[#38FF70] font-bold text-[12px] xl:text-[20px] text-black">Abrir novamente</button>
                    <button onClick={onConfirmarReceber} className="h-9 xl:h-16 w-full rounded-md bg-[#D0E200] font-bold text-[12px] xl:text-[20px] text-black">Receber item</button>
                    <button onClick={handleAbrirOutraCaixa} className="h-9 xl:h-16 w-full rounded-md bg-[#6A5DFF] font-bold text-[12px] xl:text-[20px]">Abrir outra caixa</button>
                  </div>
                </>
              )}
              <button onClick={onClose} className="h-9 xl:h-16 w-full rounded-md bg-[#ED3B46] font-bold text-[12px] xl:hidden mt-2">Fechar</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}