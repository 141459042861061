export default function Item() {
  return (
    <div className="flex flex-col border">
      <div className="border-b p-4">
        <h2 className="text-2xl text-center">Cód. 12345</h2>
        <div className="text-center">Data da Solicitação: 12/03/2024 10:05:36</div>
      </div>
      <div className="flex flex-row border-b">
        <div className="w-1/3 p-4 border-r text-center">
          <strong className="block">Forma de Pagamento:</strong>
          Pix
        </div>
        <div className="w-1/3 p-4 border-r">
          <strong className="block">Horário Processamento:</strong>
          99/99/9999 99:99
        </div>
        <div className="w-1/3 p-4">
          <strong className="block">Descrição:</strong>
          Frete Encomenda 1836<br />
          12/03/2024 13:05:36
        </div>
      </div>
      <div className="flex flex-row border-b">
        <div className="w-1/2 p-4 border-r text-center">
          <strong className="block">Valor:</strong>
          <span className="bg-[#333] rounded-full px-2">R$ 25,00</span>
        </div>
        <div className="w-1/2 p-4 text-center">
          <strong className="block">Status:</strong>
          <span className="bg-[#333] rounded-full px-2">Cancelado</span>
        </div>
      </div>
      <div className="flex flex-col items-center p-4">
        <div>Qr. Code:</div>
        <div className="w-[150px] h-[150px] bg-[#555] my-4">Imagem</div>
        <div className="w-[60%] flex flex-row">
          <input
            className="rounded-full rounded-e-none text-[#333] px-2 py-1 w-full"
            value="00020101021226860014BR.GOV.BCB.PIX2564api.rendimento.com.br/q/v2/cobv/425237a938c64f9893f84bca34c8dae55204000053039865802BR5912BOX DA SORTE6014Belo Horizonte61083038040362070503***6304FDAB"
          />
          <button
            className="rounded-full rounded-s-none ps-2 pe-4 py-1 bg-[#333]"
          >
            Copiar
          </button>
        </div>
      </div>
    </div>
  )
}