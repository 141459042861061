import { useMemo, useState } from "react";

import { mascararCEP, validate } from "../../../infra/utils";
import { useActions, useAppState } from "../../../overmind";

import cadastroService from "../../../services/cadastroService";
import authService from "../../../services/authService";

import AreaLogada from "..";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

export default function AlterarEndereco() {
  const { usuario } = useAppState()
  const { setUsuario } = useActions()
  const [dados, setDados] = useState({
    cep: usuario.endereco?.cep || '',
    logradouro: usuario.endereco?.logradouro || '',
    numero: usuario.endereco?.numero || '',
    complemento: usuario.endereco?.complemento || '',
    bairro: usuario.endereco?.bairro || '',
    cidade: usuario.endereco?.cidade || '',
    estado: usuario.endereco?.estado || ''
  })
  const [touched, setTouched] = useState({})
  const [errors, setErrors] = useState({})

  const schema = useMemo(() => {
    return [
      { campo: 'cep', required: true, type: 'cep' },
      { campo: 'logradouro', required: true },
      { campo: 'numero', required: true },
      { campo: 'complemento', required: false },
      { campo: 'bairro', required: true },
      { campo: 'cidade', required: true },
      { campo: 'estado', required: true },
    ]
  }, [])

  const handleSubmit = async ev => {
    ev.preventDefault()

    const isValid = schema.reduce((isValid, rule) => {
      if (!_validate(rule.campo, true))
        return false
      return isValid
    }, true)

    if (isValid){
      try {
        await cadastroService.alterarEndereco(
          dados.cep,
          dados.logradouro,
          dados.numero,
          dados.complemento,
          dados.bairro,
          dados.cidade,
          dados.estado,
        )

        const me = await authService.me()
        setUsuario({...usuario, ...me})
        }catch (ex) {
        debugger
        console.log(ex)
      }
    }    
  }

  const handleChange = (campo, valor) => {
    setErrors(errors => ({ ...errors, [campo]: null }))
    setDados({
      ...dados,
      [campo]: valor
    })
  }

  const handleChangeCep = (valor) => {
    setErrors(errors => ({ ...errors, cep: null }))
    setDados({
      ...dados,
      cep: mascararCEP(valor)
    })
  }

  const handleFocus = (campo) => {
    setTouched({
      ...touched,
      [campo]: true
    })
  }

  const _validate = (campo, ignoreTouched = false) => validate(touched, schema, dados, campo, setErrors, ignoreTouched)

  const handleBlur = (campo) => {
    _validate(campo)
  }

  return (
    <AreaLogada>
      <h1 className="text-3xl font-bold text-center text-verde mb-4">Endereço</h1>
      <form
        className="flex flex-col items-center gap-4 w-[600px] mx-auto"
        onSubmit={handleSubmit}
      >
        <Input
          id="cep"
          label="Cep"
          className="w-full"
          value={dados.cep || ''}
          onChange={ev => handleChangeCep(ev.target.value)}
          error={errors.cep}
          onBlur={() => handleBlur('cep')}
          onFocus={() => handleFocus('cep')}
        />
        <Input
          id="logradouro"
          label="Endereço"
          className="w-full"
          value={dados.logradouro || ''}
          onChange={ev => handleChange('logradouro', ev.target.value)}
          error={errors.logradouro}
          onBlur={() => handleBlur('logradouro')}
          onFocus={() => handleFocus('logradouro')}
        />
        <div className="flex flex-row gap-2 w-full">
          <div className="w-1/2">
            <Input
              id="numero"
              label="Número"
              className="w-full"
              value={dados.numero || ''}
              onChange={ev => handleChange('numero', ev.target.value)}
              error={errors.numero}
              onBlur={() => handleBlur('numero')}
              onFocus={() => handleFocus('numero')}
            />
          </div>
          <div className="w-1/2">
            <Input
              id="complemento"
              label="Complemento"
              className="w-full"
              value={dados.complemento || ''}
              onChange={ev => handleChange('complemento', ev.target.value)}
              error={errors.complemento}
              onBlur={() => handleBlur('complemento')}
              onFocus={() => handleFocus('complemento')}
            />
          </div>
        </div>
        <Input
          id="bairro"
          label="Bairro"
          className="w-full"
          value={dados.bairro || ''}
          onChange={ev => handleChange(ev.target.value)}
          error={errors.bairro}
          onBlur={() => handleBlur('bairro')}
          onFocus={() => handleFocus('bairro')}
        />
        <div className="flex flex-row gap-2 w-full">
          <div className="w-1/2">
            <Input
              id="cidade"
              label="Cidade"
              className="w-full"
              value={dados.cidade || ''}
              onChange={ev => handleChange('cidade', ev.target.value)}
              error={errors.cidade}
              onBlur={() => handleBlur('cidade')}
              onFocus={() => handleFocus('cidade')}
            />
          </div>
          <div className="w-1/2">
            <Input
              id="estado"
              label="Estado"
              className="w-full"
              value={dados.estado || ''}
              onChange={ev => handleChange('estado', ev.target.value)}
              error={errors.estado}
              onBlur={() => handleBlur('estado')}
              onFocus={() => handleFocus('estado')}
            />
          </div>
        </div>
        <Button
          type="submit"
          className="py-2 text-sm"
        >
          Salvar
        </Button>
      </form>
    </AreaLogada>
  )
}