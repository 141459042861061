import { useNavigate } from "react-router";
import Button from "../../../components/Button";

export default function CaixaGrandePreta({ item }) {
  const navigate = useNavigate()

  return (
    <div className='bg-[#2C2C2C] rounded-md flex flex-col text-black p-4 items-center border border-vermelho relative'>
      {!item.disponivel && (
        <div className="bg-vermelho absolute right-1 top-1 text-white py-1 rounded border border-[#1e1e1e] flex justify-center items-center w-[150px]">Indisponível</div>
      )}
      <div className='flex flex-row gap-4 items-center mt-2'>
        <div>
          <img src={item.imagem} className='w-[120px]' />
        </div>
        <div>
          <div className='uppercase text-[30px] font-bold drop-shadow-lg text-vermelho'>{item.nome}</div>
          <div className='text-[24px] font-semibold text-verde'>R${item.valorFmt}</div>
          <div className='mt-1'><Button className="bg-vermelho rounded px-3 py-1 font-semibold" onClick={() => navigate('/caixa/' + item.slug)}>VER BOX</Button></div>
        </div>
      </div>
      <div className='flex flex-row items-center gap-4 mt-2'>
        <div className='bg-verde h-[3px] w-[80px] sm:w-[102px]'></div>
        <div className='text-verde uppercase text-[12px] sm:text-[20px]'>Prêmios da Caixa</div>
        <div className='bg-verde h-[3px] w-[80px] sm:w-[102px]'></div>
      </div>
      <div className='grid grid-cols-3 sm:hidden gap-4 my-3'>
        {item.itens.slice(0, 3).map(i => (
          <div className='bg-vermelho w-[91px] h-[91px] rounded-md relative flex items-center justify-center' key={i.id}>
            <img src={i.imagem} alt={i.nome} className='w-[90px]' />
            <div className='rounded-full bg-[#9207FF] w-[24px] h-[24px] flex items-center justify-center text-[12px] absolute right-0 bottom-0 -mb-[5px] -mr-[5px] text-[#fff]'>{i.percentualFmt}</div>
          </div>
        ))}
      </div>
      <div className='grid grid-cols-5 hidden sm:grid gap-4 my-3'>
        {item.itens.map(i => (
          <div className='bg-vermelho w-[91px] h-[91px] rounded-md relative flex items-center justify-center' key={i.id}>
            <img src={i.imagem} alt={i.nome} className='w-[90px]' />
            <div className='rounded-full bg-[#9207FF] w-[24px] h-[24px] flex items-center justify-center text-[12px] absolute right-0 bottom-0 -mb-[5px] -mr-[5px] text-[#fff]'>{i.percentualFmt}</div>
          </div>
        ))}
      </div>
    </div>
  )
}