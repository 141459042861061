import Link from './Link'
import { useAppState } from '../overmind'

import logo from '../assets/images/logo-153.png'
import iconeFacebook from '../assets/images/icone-facebook.png'
import iconeTwitter from '../assets/images/icone-twitter.png'
import iconeVimeo from '../assets/images/icone-vimeo.png'
import iconeYoutube from '../assets/images/icone-youtube.png'


import Button from './Button'

export default function DefaultFooter() {
  const { usuario } = useAppState()


  return (
    <footer className='bg-black pt-[20px]'>
      <div className="flex flex-col p-[25px] xl:p-[50px]">
        {/* <form className='bg-white rounded-lg p-[25px] flex flex-col xl:flex-row items-center xl:h-[80px] gap-2'>
          <div className='text-header text-black'>
            Receba nossa Newsletters
          </div>
          <div className='flex-1 hidden xl:block'></div>
          <input className='border border-[#bbb] w-full xl:w-[530px] h-[55px] px-10 text-black' placeholder='Seu e-mail' />
          <Button type="submit" tipo="newsletter">Inscreva-se</Button>
        </form> */}
        <div className='flex flex-row justify-between my-10 items-center'>
          <div className='flex-row gap-3 uppercase hidden xl:flex grow xl:grow-0'>
            <Link manterAparencia navigateTo="/home">HOME</Link><span>|</span>
            <Link manterAparencia navigateTo="/como-funciona">COMO FUNCIONA</Link><span>|</span>
            {!usuario && (
              <>
                <Link manterAparencia navigateTo="/cadastro">CADASTRE-SE</Link><span>|</span>
              </>
            )}
            {/* <Link manterAparencia className="text-verde" navigateTo="/collab">COLLAB</Link><span>|</span> */}
            <Link manterAparencia navigateTo="/contato">CONTATO</Link>
          </div>
          <div className='flex flex-row gap-6 items-center justify-center grow xl:grow-0'>
            <Link newTab externalHref="https://www.facebook.com/sortebox.oficial" manterAparencia><img src={iconeFacebook} alt="Facebook" /></Link>
            <Link newTab externalHref="https://www.instagram.com/sortebox.oficial/" manterAparencia>
              <svg aria-hidden="true" className="w-[24px] fill-[#81ff31]" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg>
            </Link>
            <Link newTab externalHref="https://www.tiktok.com/@sortebox.oficial" manterAparencia>
              <svg aria-hidden="true" className="w-[22px] fill-[#81ff31]" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path></svg>
            </Link>
          </div>
        </div>
        <div className='border-t border-t-[#2C2626] flex flex-col xl:flex-row justify-between items-center -mx-[25px] px-[25px] pt-[25px] xl:pt-[50px]'>
          <img src={logo} alt="Box da Sorte" className='w-[150px] -mx-[8px]' />
          <div className='text-[14px] flex flex-col xl:flex-row items-center gap-1 mt-10 xl:mt-0 font-regular'>
            <span>©{new Date().getFullYear()} Box da Sorte</span> <span className='hidden xl:block'>|</span>
            <span>Todos os direitos reservados</span> <span className='hidden xl:block'>|</span>
            <span>CNPJ: 52.559.327/0001-00</span>
          </div>
        </div>
        <div>
        </div>
      </div>
    </footer>
  )
}