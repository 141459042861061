import { useMemo } from 'react'
import iconeDoar from '../../assets/images/icone-botao-doar-preto.png'
import iconeFechar from '../../assets/images/icone-botao-fechar-branco.png'

export default function Doar({
  item,
  sorteado,
  onEscolherInstituicao,
  onFechar
}) {

  const itemSorteado = useMemo(() => {
    if (!sorteado)
      return null

    return item.itens.find(el => el.id == sorteado.idItemSorteado)
  }, [sorteado, item.itens])

  return (
    <div className="fixed inset-0 bg-[#0007] z-40">
      <div className="xl:max-w-screen-xl mx-auto p-2 xl:p-10">
        <div className="xl:mt-[100px] p-4 xl:p-40 rounded rounded-md bg-white text-[#1E1E1E] items-center flex flex-col xl:flex-row">
          <div>
            <img src={itemSorteado.imagem} alt={itemSorteado.nome} className='h-[100px] xl:h-[242px]' />
          </div>
          <div className='flex flex-col gap-2'>
            <div className='font-bold text-[48px] hidden xl:block'>Você ganhou um {itemSorteado.nome}!</div>
            <div className='xl:hidden text-center uppercase mt-2'>
              <span className='text-[16px] font-bold block'>{itemSorteado.nome}</span>
              R$<span className='text-[24px] font-bold'>{itemSorteado.valorFmt}</span>
            </div>
            <div className='font-bold text-[12px] xl:text-[14px] text-center xl:text-start'>
              Deseja doar esse item? <br />
              Ele será entregue em uma das instituições cadastradas.
            </div>
            <div className='flex flex-col xl:flex-row gap-2 xl:gap-4'>
              <button onClick={onEscolherInstituicao} className='h-10 w-full bg-[#00E2B9] xl:w-[228px] py-2 rounded-md flex flex-row gap-3 justify-center items-center font-bold text-[12px]'><img src={iconeDoar} alt="Doar para ONG" /> Doar para ONG</button>
              <button onClick={onFechar} className='h-10 w-full bg-[#ED3B46] xl:w-[228px] py-2 rounded-md flex flex-row gap-3 justify-center items-center font-bold text-[12px] text-white'><img src={iconeFechar} alt="Fechar" />  Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}