import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import Swal from 'sweetalert2'


import Button from '../../components/Button'
import Input from '../../components/Input'
import Link from '../../components/Link'

import cadastroService from '../../services/cadastroService'
import authService from '../../services/authService'

import { mascararCpf, mascararData, mascararTelefone, validate } from '../../infra/utils'

import { useActions } from '../../overmind'

import banner1 from '../../assets/images/banner-1.png'

import creditoService from '../../services/creditoService'

export default function Cadastro() {
  const [dados, setDados] = useState({})
  const [touched, setTouched] = useState({})
  const [errors, setErrors] = useState({})
  const navigate = useNavigate()
  const { state } = useLocation()
  const { returnUrl } = state || {}
  const { setUsuario } = useActions()

  const schema = useMemo(() => {
    return [
      { campo: 'nome', required: true },
      { campo: 'sobrenome', required: true },
      { campo: 'email', required: true, type: 'email' },
      { campo: 'dataNascimento', required: true, type: 'date' },
      { campo: 'celular', required: true, type: 'telefone' },
      { campo: 'cpf', required: true, type: 'cpf' },
      { campo: 'senha', required: true, type: 'password', validateOnChange: true },
      {
        campo: 'confirmarSenha',
        required: true,
        validateOnChange: true,
        validateFunction: (dados, campo, setErrors) => {
          const value = dados[campo]
          if (dados.senha !== value) {
            setErrors(errors => ({ ...errors, [campo]: '* A nova senha e a confirmação estão diferentes' }))
            return false
          }
          return true
        },
      },
      { 
        campo: 'aceite', 
        validateOnChange: true,
        validateFunction: (dados, campo, setErrors) => {
          const value = dados[campo]
          if (!dados.aceite) {
            setErrors(errors => ({ ...errors, [campo]: '* Você precisa aceitar os termos de uso e politica de privacidade para continuar' }))
            return false
          }
          return true
        }
      }
    ]
  }, [])

  const handleChange = (campo, valor) => {
    setErrors(errors => ({ ...errors, [campo]: null }))
    setDados({
      ...dados,
      [campo]: valor
    })
  }

  const handleChangeCelular = valor => {
    setErrors(errors => ({ ...errors, 'celular': null }))
    setDados({
      ...dados,
      celular: mascararTelefone(valor)
    })
  }

  const handleChangeCPF = valor => {
    setErrors(errors => ({ ...errors, 'cpf': null }))
    setDados({
      ...dados,
      cpf: mascararCpf(valor)
    })
  }

  const handleChangeDataNascimento = valor => {
    setErrors(errors => ({ ...errors, 'dataNascimento': null }))
    setDados({
      ...dados,
      dataNascimento: mascararData(valor)
    })
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    const isValid = schema.reduce((isValid, rule) => {
      if (!_validate(rule.campo, true))
        return false
      return isValid
    }, true)

    if (isValid) {
      try {
        const authUser = await cadastroService.criar(
          dados.nome,
          dados.email,
          dados.cpf,
          dados.celular,
          dados.senha
        )
        setUsuario(authUser)

        const me = await authService.me()
        setUsuario({ ...authUser, ...me })

        await Swal.fire({
          text: 'Cadastro efetuado com sucesso!'
        })
        if (returnUrl)
          navigate(returnUrl)
        else
          navigate('/sua-conta')
      } catch (ex) {
        debugger
        console.log(ex)
      }

    }
  }

  const handleEntrar = () => {
    navigate('/login', { state: { returnUrl } })
  }

  const handleFocus = (campo) => {
    setTouched({
      ...touched,
      [campo]: true
    })
  }

  const _validate = (campo, ignoreTouched = false) => validate(touched, schema, dados, campo, setErrors, ignoreTouched)

  const handleBlur = (campo) => {
    _validate(campo)
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <div>
        <img src={banner1} />
      </div>
      <form
        className="bg-white p-5 flex flex-col items-stretch gap-4 w-full text-[#1e1e1e]"
        onSubmit={handleSubmit}
      >
        <h1 className='text-[24px] xl:text-[48px] font-bold'>Crie sua conta. É grátis</h1>

        <div className='grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5'>
          <div>
            <Input
              id="nome"
              label="Nome"
              className="w-full"
              value={dados.nome || ''}
              onChange={ev => handleChange('nome', ev.target.value)}
              error={errors.nome}
              onBlur={() => handleBlur('nome')}
              onFocus={() => handleFocus('nome')}
            />
          </div>
          <div>
            <Input
              id="sobrenome"
              label="Sobrenome"
              className="w-full"
              value={dados.sobrenome || ''}
              onChange={ev => handleChange('sobrenome', ev.target.value)}
              error={errors.sobrenome}
              onBlur={() => handleBlur('sobrenome')}
              onFocus={() => handleFocus('sobrenome')}
            />
          </div>
          <div>
            <Input
              id="cpf"
              label="CPF"
              className="w-full"
              value={dados.cpf || ''}
              onChange={ev => handleChangeCPF(ev.target.value)}
              error={errors.cpf}
              onBlur={() => handleBlur('cpf')}
              onFocus={() => handleFocus('cpf')}
            />
          </div>
          <div>
            <Input
              id="dataNascimento"
              label="Data de Nascimento"
              className="w-full"
              value={dados.dataNascimento || ''}
              onChange={ev => handleChangeDataNascimento(ev.target.value)}
              error={errors.dataNascimento}
              onBlur={() => handleBlur('dataNascimento')}
              onFocus={() => handleFocus('dataNascimento')}
            />
          </div>
          <div>
            <Input
              id="email"
              label="E-mail"
              className="w-full"
              value={dados.email || ''}
              onChange={ev => handleChange('email', ev.target.value)}
              error={errors.email}
              onBlur={() => handleBlur('email')}
              onFocus={() => handleFocus('email')}
            />
          </div>
          <div>
            <Input
              id="celular"
              label="DDD + Telefone"
              className="w-full"
              value={dados.celular || ''}
              onChange={ev => handleChangeCelular(ev.target.value)}
              error={errors.celular}
              onBlur={() => handleBlur('celular')}
              onFocus={() => handleFocus('celular')}
            />
          </div>
          <div>
            <Input
              id="senha"
              label="Senha"
              type="password"
              className="w-full"
              value={dados.senha || ''}
              onChange={ev => handleChange('senha', ev.target.value)}
              error={errors.senha}
              onBlur={() => handleBlur('senha')}
              onFocus={() => handleFocus('senha')}
            />
          </div>
          <div>
            <Input
              id="confirmarSenha"
              label="Confirme a senha"
              type="password"
              className="w-full"
              value={dados.confirmarSenha || ''}
              onChange={ev => handleChange('confirmarSenha', ev.target.value)}
              error={errors.confirmarSenha}
              onBlur={() => handleBlur('confirmarSenha')}
              onFocus={() => handleFocus('confirmarSenha')}
            />
          </div>
        </div>

        <div className='xl:mt-20'>
          <label className='text-[14px] flex flex-row gap-2 items-start'>
            <input type='checkbox' checked={dados.aceite} onChange={ev => handleChange('aceite', ev.target.checked)} />
            <span>
              Você concorda com os <Link manterAparencia className="text-[#7949FF]" navigateTo="/termos-uso">Termos de Uso</Link> e
              a <Link manterAparencia className="text-[#7949FF]" navigateTo="/politica-privacidade">Política de Privacidade</Link> do SorteBox, e
              também, em receber comunicações via Whatsapp.
            </span>
          </label>
          {errors.aceite && <div className="text-[14px] text-[#f00]">{errors.aceite}</div>}
        </div>

        <div className='mt-2'>
          <Button
            type="submit"
            className="rounded-md bg-[#7949FF] text-white text-[24px] px-8 py-1 h-[61px] font-semibold w-full xl:w-auto"
          >
            Criar conta
          </Button>
        </div>

        <div className='text-center w-full'>
          Já tem uma conta? <Link manterAparencia className="text-[#7949FF]" onClick={handleEntrar}>Entrar</Link>
        </div>
      </form>
    </div>
  )
}