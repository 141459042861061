import Home from "../pages/home";
import Cadastro from "../pages/cadastro";
import AlterarSenha from "../pages/alterarSenha";
import PoliticaPrivacidade from "../pages/politicaPrivacidade";
import QuemSomos from '../pages/quemSomos'
import Atendimento from '../pages/atendimento'
import TermosUso from "../pages/termosUso";
import DetalhesCaixa from "../pages/detalhesCaixa";
import MeusItens from "../pages/areaLogada/meusItens";
import AlterarCadastro from "../pages/areaLogada/alterarCadastro";
import AlterarEndereco from "../pages/areaLogada/alterarEndereco";
import AdicionarCredito from "../pages/areaLogada/adicionarCredito";
import MeuFinanceiro from "../pages/areaLogada/meuFinanceiro";
import MinhasEntregas from "../pages/areaLogada/minhasEntregas";
import Historico from "../pages/areaLogada/historico";
import ComoFunciona from "../pages/comoFunciona";
import Collab from "../pages/collab";
import Contato from "../pages/contato";
import LembrarSenha from "../pages/lembrarSenha";

import Login from '../pages/login'

const routes = [
  { path: '/home', exact: true, name: "Home", element: Home },
  { path: '/login', exact: true, name: "Login", element: Login },
  { path: '/lembrar-senha', exact: true, name: "Lembrar Senha", element: LembrarSenha },
  { path: '/collab', exact: true, name: "Collab", element: Collab },
  { path: '/contato', exact: true, name: "Contato", element: Contato },
  { path: '/cadastro', exact: true, name: "Cadastro", element: Cadastro },
  { path: '/caixa/:slug', exact: true, name: "Detalhes da Caixa", element: DetalhesCaixa },
  { path: '/alterar-senha', exact: true, name: "Alterar Senha", element: AlterarSenha, auth: true },
  { path: '/quem-somos', exact: true, element: QuemSomos },
  { path: '/como-funciona', exact: true, element: ComoFunciona },
  { path: '/atendimento', exact: true, element: Atendimento },
  { path: '/meus-itens', exact: true, element: MeusItens, auth: true },
  { path: '/alterar-cadastro', exact: true, element: AlterarCadastro, auth: true },
  { path: '/alterar-endereco', exact: true, element: AlterarEndereco, auth: true },
  { path: '/adicionar-credito', exact: true, element: AdicionarCredito, auth: true },
  { path: '/meu-financeiro', exact: true, element: MeuFinanceiro, auth: true },
  { path: '/minhas-entregas', exact: true, element: MinhasEntregas, auth: true },
  { path: '/historico', exact: true, element: Historico, auth: true },
  { path: '/politica-privacidade', exact: true, element: PoliticaPrivacidade },
  { path: '/termos-uso', exact: true, element: TermosUso },
]

export default routes