import { setLocalStorage, setSessionStorage } from "../infra/utils"
import { setToken } from "../services/service"

const set = (campo) => ({ state }, value) => {
  state[campo] = value
}

const setWithLocalStorage = (campo) => ({ state }, value) => {
  state[campo] = value
  setLocalStorage(campo, value)
}

export const setLocalizacao = setWithLocalStorage('localizacao')

export const setUsuario = ({ state }, value) => {
  state.usuario = value
  setSessionStorage('usuario', value)
  if (value)
    setToken(value.token)
  else
    setToken(null)
}