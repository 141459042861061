import CustomError from "../infra/Error"
import { get, post } from "./service"

class AuthService {
  async login(email, senha) {
    const res = await post('front/auth', { email, senha })
    if (res.success) {
      return res.data
    } else {
      throw new CustomError(res.message)
    }
  }

  async me() {
    const res = await get(`front/auth/me`)
    if (res.success) {
      return res.data
    }
  }

  async lembrarSenha(email) {
    const res = await post(`front/auth/lembrar-senha`, { email })
    if (res.success) {
      return res.data
    }
  }

  async alterarSenha(senhaAtual, novaSenha) {
    const res = await post(`front/auth/alterar-senha`, { senhaAtual, novaSenha })
    if (res.success) {
      return true
    } else {
      if (res.message === 'Acesso não autorizado')
        return false

      throw new CustomError(res.message, res.data)
    }
  }
}

const authService = new AuthService()
export default authService