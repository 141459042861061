import { useEffect, useMemo, useState } from 'react'
import iconeReceber from '../../assets/images/icone-botao-receber-preto.png'
import iconeFechar from '../../assets/images/icone-botao-fechar-branco.png'
import pedidoService from '../../services/pedidoService'
import socketService from '../../services/socketService'

const modos = {
  confirmar: 1,
  pedidoGerado: 2
}

export default function Receber({
  item,
  sorteado,
  onFechar
}) {
  const [modo, setModo] = useState(modos.confirmar)
  const [pedido, setPedido] = useState()

  useEffect(() => {

    let id
    if (modo == modos.pedidoGerado && pedido.frete.dadosPix) {
      id = socketService.assinar({
        update: (evento, dados) => {
          if (evento == 'pix_recebido') {
            if (pedido.frete.dadosPix.idRequisicao == dados.idRequisicao) {
              alert(`Reconhecemos o recebimento de R$ ${dados.valorPagoFmt} para este PIX.`).then(() => {
                onFechar && onFechar()
              })
            }
          }
        }
      })
    }

    if (id)
      return () => socketService.cancelarAssinatura(id)

  }, [modo, pedido])

  const itemSorteado = useMemo(() => {
    if (!sorteado)
      return null

    return item.itens.find(el => el.id == sorteado.idItemSorteado)
  }, [sorteado, item.itens])

  const handleReceber = async () => {
    try {
      const pedido = await pedidoService.criar([sorteado.idAbertura])
      setPedido(pedido)
      setModo(modos.pedidoGerado)
    } catch (ex) {
      alert(ex.message)
    }
  }

  const copiar = () => {
    window.navigator.clipboard.writeText(pedido.frete.dadosPix.text).then(() => {
      alert('Código PIX copiado')
    })
  }

  return (
    <div className="fixed inset-0 bg-[#0007]">
      <div className="xl:max-w-screen-xl mx-auto p-2 xl:p-10">
        <div className="xl:mt-[100px] p-4 xl:p-40 rounded rounded-md bg-white text-[#1E1E1E] items-center flex flex-col xl:flex-row">
          <div>
            <img src={itemSorteado.imagem} alt={item.nome} className='h-[180px] xl:h-[242px]' />
          </div>
          <div className='flex flex-col gap-2'>
            {modo == modos.confirmar && (
              <>
                <div className='font-bold text-[48px] xl:leading-[53px] hidden xl:block'>Você ganhou um {itemSorteado.nome}!</div>
                <div className='xl:hidden text-center uppercase mt-2'>
                  <span className='text-[16px] font-bold block'>{itemSorteado.nome}</span>
                  R$<span className='text-[24px] font-bold'>{itemSorteado.valorFmt}</span>
                </div>
                <div className='font-bold text-[12px] xl:text-[14px] text-center xl:text-start'>
                  Deseja receber esse item? O item será enviado para a sua casa. Para acompanhar o envio acesse minhas entregas.
                </div>
                <div className='flex flex-col xl:flex-row gap-2 xl:gap-4'>
                  <button onClick={handleReceber} className='h-10 w-full bg-[#00E2B9] xl:w-[228px] py-3 rounded-md flex flex-row gap-3 justify-center items-center font-bold text-[12px]'><img src={iconeReceber} alt="Receber Item" /> Receber Item</button>
                  <button onClick={onFechar} className='h-10 w-full bg-[#ED3B46] xl:w-[228px] py-3 rounded-md flex flex-row gap-3 justify-center items-center font-bold text-[12px] text-white'><img src={iconeFechar} alt="Fechar" />  Fechar</button>
                </div>
              </>
            )}
            {modo == modos.pedidoGerado && (
              <>
                <div className='font-bold text-[24px] text-center xl:text-start xl:text-[48px] xl:leading-[53px]'>Seu pedido será<br />processado em breve.</div>
                <div className='font-bold text-[14px] text-center xl:text-start'>
                  Para acompanhar o envio acesse <span className='underline'>MINHAS ENTREGAS</span>
                </div>
                {pedido.frete.dadosPix && (
                  <div className='rounded bg-[#f2f2f2] border p-3 flex flex-col items-center'>
                    <div className='mb-2'>Para receber o pedido você precisa pagar o frete de R$ {pedido.frete.valorFmt}</div>
                    <img src={pedido.frete.dadosPix.urlPng} className="w-[250px] my-4" alt="QRCode de pagamento do Pix" />
                    <div className="w-full flex flex-row">
                      <input
                        className="rounded-full rounded-e-none text-[#333] px-2 py-1 w-full border-[#333] border"
                        value={pedido.frete.dadosPix.text}
                      />
                      <button
                        className="rounded-full rounded-s-none ps-2 pe-4 py-1 bg-[#DDD] border border-s-0"
                        onClick={copiar}
                      >
                        Copiar
                      </button>
                    </div>
                  </div>
                )}
                <div className='flex flex-row gap-4'>
                  <button
                    onClick={onFechar}
                    className='bg-[#ED3B46] w-full xl:w-[228px] py-3 rounded-md flex flex-row gap-3 justify-center items-center font-bold text-[12px] text-white'
                  ><img src={iconeFechar} alt="Fechar" />  Fechar</button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}