import { useMemo, useState } from 'react'

import banner1 from '../../assets/images/banner-1.png'
import banner1Mobile from '../../assets/images/banner-1-mobile.png'
import { alert, mascararTelefone, validate } from '../../infra/utils'

import Input from '../../components/Input'
import Select from '../../components/Select'
import Button from '../../components/Button'
import contatoService from '../../services/contatoService'

const initialData = {
  assunto: 'Pedidos (Compra Única/Assinatura)'
}

export default function Contato() {
  const [dados, setDados] = useState({ ...initialData })
  const [touched, setTouched] = useState({})
  const [errors, setErrors] = useState({})

  const schema = useMemo(() => {
    return [
      { campo: 'nome', required: true },
      { campo: 'email', required: true, type: 'email' },
      { campo: 'celular', required: true, type: 'telefone' },
      { campo: 'assunto', required: true },
      { campo: 'mensagem', required: true },
    ]
  }, [])

  const handleChange = (campo, valor) => {
    setErrors(errors => ({ ...errors, [campo]: null }))
    setDados({
      ...dados,
      [campo]: valor
    })
  }

  const handleChangeCelular = valor => {
    setErrors(errors => ({ ...errors, 'celular': null }))
    setDados({
      ...dados,
      celular: mascararTelefone(valor)
    })
  }

  const handleFocus = (campo) => {
    setTouched({
      ...touched,
      [campo]: true
    })
  }

  const _validate = (campo, ignoreTouched = false) => validate(touched, schema, dados, campo, setErrors, ignoreTouched)

  const handleBlur = (campo) => {
    _validate(campo)
  }


  const handleSubmit = async ev => {
    ev.preventDefault()
    const isValid = schema.reduce((isValid, rule) => {
      if (!_validate(rule.campo, true))
        return false
      return isValid
    }, true)

    if (isValid) {
      try {
        await contatoService.enviar(dados.nome, dados.email, dados.celular, dados.assunto, dados.mensagem)
        await alert('Sua mensagem foi recebida e entraremos em contato em breve')
        setDados({ ...initialData })
      } catch (ex) {
        debugger
        console.log(ex)
      }
    }
  }

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='w-full h-[300px] hidden xl:block' style={{
        background: `url(${banner1})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className='w-full h-[300px] xl:hidden' style={{
        background: `url(${banner1Mobile})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <form
        className='bg-white p-4 xl:p-10 flex flex-col items-stretch gap-2 xl:gap-4 w-full text-[#1e1e1e]'
        onSubmit={handleSubmit}
      >
        <h1 className='text-[24px] xl:text-[48px] font-semibold xl:font-bold text-center xl:text-start'>Contato</h1>
        <div className='flex justify-center'>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.0329763766795!2d-46.66105122293192!3d-23.603150263132036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a1a8a7c9235%3A0x7b8ef5d66227b50d!2sAv.%20Chibar%C3%A1s%2C%20257%20-%20Planalto%20Paulista%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004076-000!5e0!3m2!1spt-BR!2sbr!4v1715276270815!5m2!1spt-BR!2sbr"
            className="border-0 w-full h-[450px]"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className='grid grid-cols-1 gap-x-10 gap-y-5'>
          <div>
            <Input
              id="nome"
              label="Nome"
              className="w-full"
              value={dados.nome || ''}
              onChange={ev => handleChange('nome', ev.target.value)}
              error={errors.nome}
              onBlur={() => handleBlur('nome')}
              onFocus={() => handleFocus('nome')}
            />
          </div>
          <div>
            <Input
              id="email"
              label="E-mail"
              className="w-full"
              value={dados.email || ''}
              onChange={ev => handleChange('email', ev.target.value)}
              error={errors.email}
              onBlur={() => handleBlur('email')}
              onFocus={() => handleFocus('email')}
            />
          </div>
          <div>
            <Input
              id="celular"
              label="DDD + Telefone"
              className="w-full"
              value={dados.celular || ''}
              onChange={ev => handleChangeCelular(ev.target.value)}
              error={errors.celular}
              onBlur={() => handleBlur('celular')}
              onFocus={() => handleFocus('celular')}
            />
          </div>
          <div>
            <Select
              label="Assunto"
              options={[
                { value: "Pedidos (Compra Única/Assinatura)", label: "Pedidos (Compra Única/Assinatura)" },
                { value: "Reembolsos", label: "Reembolsos" },
                { value: "Reclamações", label: "Reclamações" },
                { value: "Outros Assuntos", label: "Outros Assuntos" },
              ]}
              value={dados.assunto || ''}
              onChange={ev => handleChange('assunto', ev.target.value)}
              error={errors.assunto}
              onBlur={() => handleBlur('assunto')}
              onFocus={() => handleFocus('assunto')}
            />
          </div>
          <div>
            <div className={"flex flex-col gap-1 w-full "}>
              <label className="text-[14px]" htmlFor="mensagem">Mensagem</label>
              <textarea
                className='rounded p-3 h-[120px] text-[#1e1e1e] bg-[#ECE9E9] border border-[#B8B8B8] disabled:text-[#555]'
                value={dados.mensagem || ''}
                onChange={ev => handleChange('mensagem', ev.target.value)}
                onBlur={() => handleBlur('mensagem')}
                onFocus={() => handleFocus('mensagem')}
              ></textarea>
              {errors.mensagem && <div className="text-[14px] text-[#f00]">{errors.mensagem}</div>}
            </div>
          </div>
          <div className='mt-2'>
            <Button
              type="submit"
              className="rounded-md bg-[#7949FF] text-white text-[24px] px-8 py-1 h-[61px] font-semibold w-full"
            >
              Enviar
            </Button>
          </div>

        </div>
      </form >
    </div >
  )
}