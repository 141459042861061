import { useNavigate } from "react-router";
import Button from "../../../components/Button";

export default function CaixaPequena({
  item
}) {
  const navigate = useNavigate()

  return (
    <div
      className={`rounded-md border p-4 flex flex-row sm:flex-col items-center gap-4 relative`}
      style={{
        borderColor: item.cor,
        background: `linear-gradient(to bottom, ${item.cor}, #2C2C2CB2)`
      }}
    >
      {!item.disponivel && (
        <div className="bg-vermelho absolute right-1 top-1 text-white py-1 rounded border border-[#1e1e1e] flex justify-center items-center w-[150px]">Indisponível</div>
      )}
      <div><img src={item.imagem} className='w-[180px]' /></div>
      <div className="flex flex-col">
        <div
          className='h-[60px] text-center uppercase text-[20px] font-bold drop-shadow-md mt-2'
          style={{
            color: item.cor
          }}
        >
          {item.nome}
        </div>
        <div className='w-full'>
          <button
            className="rounded px-3 py-2 font-bold w-full"
            style={{ background: item.cor, color: item.corTexto }}
            onClick={() => navigate('/caixa/' + item.slug)}
          >
            VER BOX R${item.valorFmt}
          </button>
        </div>
      </div>
    </div>
  )
}