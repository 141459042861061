import { io } from 'socket.io-client'
import { nanoid } from 'nanoid'

class SocketService {
  autenticado = false
  assinantes = []

  constructor() {
    this.socket = io(process.env.REACT_APP_API_URL, {
      autoConnect: false
    });

    this.socket.on('saldo', (...args) => this.notificar('saldo', ...args))
    this.socket.on('pix_recebido', (...args) => this.notificar('pix_recebido', ...args))
  }

  notificar(evento, ...args) {
    console.log('SocketService', { evento, ...args })
    for (const reg of this.assinantes)
      reg.assinante.update(evento, ...args)
  }

  assinar(assinante) {
    const id = nanoid()
    this.assinantes.push({
      id,
      assinante
    })
    return id
  }

  cancelarAssinatura(id) {
    this.assinantes = this.assinantes.filter(el => el.id != id)
  }

  autenticar(token) {
    if (this.autenticado) 
      return
    this.socket.on('connect', () => {
      this.socket.emit('auth', token)
      this.autenticado = true
    })
    this.socket.connect()
  }

  desconectar() {
    this.autenticado = false
    this.socket.disconnect()
  }
}

const socketService = new SocketService()
export default socketService