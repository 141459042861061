import { useEffect, useState } from "react";
import AreaLogada from "..";

import Select from "../../../components/Select"
import Loading from '../../../components/Loading'

import Item from "./Item";
import historicoService from "../../../services/historicoService";

export default function Historico() {
  const [status, setStatus] = useState('')
  const [dados, setDados] = useState()

  useEffect(() => {
    carregar()
  }, [status])

  const carregar = () => {
    historicoService.listar(status).then(setDados)
  }

  if (!dados)
    return <Loading />

  return (
    <AreaLogada>
      <div className="text-[#1E1E1E]">
        <h1 className='text-[24px] xl:text-[48px] text-center xl:text-start font-bold mb-6'>Histórico</h1>
        <Select
          options={[
            { value: "", label: "Selecione o status" },
            { value: "credito", label: "Crédito na Conta Digital" },
            { value: "abertura", label: "Abertura de Box" },
            { value: "troca", label: "Troca com a Plataforma" },
            { value: "frete", label: "Pagamento de Frete" },
          ]}
          value={status}
          onChange={ev => setStatus(ev.target.value)}
        />

        <div className="mt-4 flex flex-col gap-2">
          {dados.map(el => (
            <Item key={el.id} item={el} />
          ))}
        </div>
      </div>
    </AreaLogada>
  )
}