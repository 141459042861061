import { post, get } from "./service"

class CreditoService {
  async saldo() {
    let url = 'front/credito/saldo'
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }

  async adicionar(valor) {
    let url = 'front/credito'
    const res = await post(url, {
      valor
    })
    if (res.success) {
      return res.data
    }
  }
}

const creditoService = new CreditoService()
export default creditoService