import { useEffect, useRef } from 'react'
import smartphone from '../assets/images/smartphone.png'

export default function ItemCaixaSorteio({ item, selected }) {
  return (
    <div
      style={{
        background: 'linear-gradient(180deg, #4D4D4D 0%, #FFFFFF 19%, #C4B95A 70%, #B3B3B3 100%)',
        borderRadius: 20
      }}
    >
      <div
        className='flex flex-col gap-4 justify-center items-center w-[176px] h-[276px] p-2'
        style={{
          margin: 2,
          background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(44, 44, 44, 0.7) 39%, rgba(44, 44, 44, 0.7) 75.69%) #1E1E1E',
          borderRadius: 18
        }}
      >
        <img src={item.imagem} alt={item.nome} className='w-[110px]' />
        <div className='text-center uppercase text-[16px] drop-shadow-md font-bold h-[60px]'>{item.nome}</div>
        <div className="text-verde text-[16px]">R$<span className='font-bold'>{item.valorFmt}</span></div>
      </div>
    </div>
  )
}