import { get, post, put } from "./service"

class CompraService {
  async trocar(id) {
    let url = 'front/compra/' + id + '/trocar'
    const res = await post(url)
    if (res.success) {
      return res.data
    }
  }

  async doar(id, idInstituicao) {
    let url = 'front/compra/' + id + '/doar'
    const res = await post(url, { idInstituicao })
    if (res.success) {
      return res.data
    }
  }

  async listar(status) {
    let url = 'front/compra?status=' + status
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }
}

const compraService = new CompraService()
export default compraService