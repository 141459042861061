import { useState } from 'react'

import Link from '../../../components/Link'
import ModalItem from '../../../components/ModalItem'

export default function Item({
  item,
}) {
  const [itemDetalhe, setItemDetalhe] = useState()

  const handleDetalhes = () => {
    setItemDetalhe(item.item.id)
  }

  return (
    <div className="flex flex-col bg-[#EBEBEB] rounded px-4 py-6 gap-2">
      <div className="flex-1">
        <div className="flex flex-col xl:flex-row items-center gap-2">
          <div className="w-full xl:w-1/2">
            <div className="flex flex-row gap-2">
              <div className="w-[90px]">
                <img src={item.item.imagem} className="w-[90px]" alt={item.item.nome} />
              </div>
              <div>
                <div className="font-bold text-[20px]">{item.item.nome}</div>
                <div className="text-[14px]"><strong className="font-bold">Valor:</strong> R${item.item.valorFmt}</div>
                <div><Link manterAparencia className="text-[13px] text-[#3E880F] underline" onClick={handleDetalhes}>Ver Detalhes</Link></div>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/2 text-[12px] xl:text-[15px] drop-shadow-md">
            <div><strong className="font-bold">CÓD. {item.id}</strong></div>
            <div><strong className="font-bold">DATA SORTEIO:</strong> {item.dataFmt}</div>
            <div><strong className="font-bold">VALOR ABERTURA:</strong> R${item.caixa.valorFmt}</div>
          </div>
        </div>
      </div>
      {itemDetalhe && <ModalItem idItem={itemDetalhe} onFechar={() => setItemDetalhe()} />}
    </div>
  )
}