import { useMemo, useState } from "react";

import { datePtBrToIso, formatDate, mascararCEP, mascararCpf, mascararData, mascararTelefone, validate } from "../../../infra/utils";
import { useActions, useAppState } from "../../../overmind";

import AreaLogada from "..";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

import cadastroService from "../../../services/cadastroService";
import authService from "../../../services/authService";
import Link from "../../../components/Link";

export default function AlterarCadastro() {
  const { usuario } = useAppState()
  const { setUsuario } = useActions()
  const [dados, setDados] = useState({
    nome: usuario.nome,
    sobrenome: usuario.sobrenome,
    email: usuario.email,
    celular: usuario.celular,
    cpf: usuario.cpf,
    sexo: usuario.sexo,
    dataNascimento: usuario.dataNascimento ? formatDate(new Date(usuario.dataNascimento)) : '',
    cep: usuario.endereco?.cep,
    logradouro: usuario.endereco?.logradouro,
    numero: usuario.endereco?.numero,
    complemento: usuario.endereco?.complemento,
    bairro: usuario.endereco?.bairro,
    cidade: usuario.endereco?.cidade,
    estado: usuario.endereco?.estado
  })
  const [touched, setTouched] = useState({})
  const [errors, setErrors] = useState({})

  const schema = useMemo(() => {
    return [
      { campo: 'nome', required: true },
      { campo: 'sobrenome', required: true },
      { campo: 'email', required: true, type: 'email' },
      { campo: 'celular', required: true, type: 'telefone' },
      { campo: 'cpf', required: true, type: 'cpf' },
      { campo: 'dataNascimento', required: false, type: 'date' },
      { campo: 'cep', required: true, type: 'cep' },
      { campo: 'logradouro', required: true },
      { campo: 'numero', required: true },
      { campo: 'complemento', required: false },
      { campo: 'bairro', required: true },
      { campo: 'cidade', required: true },
      { campo: 'estado', required: true },
    ]
  }, [])

  const handleSubmit = async ev => {
    ev.preventDefault()
    const isValid = schema.reduce((isValid, rule) => {
      if (!_validate(rule.campo, true))
        return false
      return isValid
    }, true)

    if (isValid) {
      try {
        await cadastroService.alterar(
          dados.nome,
          dados.sobrenome,
          dados.email,
          dados.cpf,
          dados.celular,
          dados.sexo,
          dados.dataNascimento ? datePtBrToIso(dados.dataNascimento) : null,
          dados.cep,
          dados.logradouro,
          dados.numero,
          dados.complemento,
          dados.bairro,
          dados.cidade,
          dados.estado
        )

        const me = await authService.me()
        setUsuario({ ...usuario, ...me })
      } catch (ex) {
        debugger
        console.log(ex)
      }
    }
  }

  const handleChange = (campo, valor) => {
    setErrors(errors => ({ ...errors, [campo]: null }))
    setDados({
      ...dados,
      [campo]: valor
    })
  }

  const handleChangeCelular = valor => {
    setErrors(errors => ({ ...errors, 'celular': null }))
    setDados({
      ...dados,
      celular: mascararTelefone(valor)
    })
  }

  const handleChangeCPF = valor => {
    setErrors(errors => ({ ...errors, 'cpf': null }))
    setDados({
      ...dados,
      cpf: mascararCpf(valor)
    })
  }

  const handleChangeDataNascimento = valor => {
    setErrors(errors => ({ ...errors, 'dataNascimento': null }))
    setDados({
      ...dados,
      dataNascimento: mascararData(valor)
    })
  }

  const handleChangeCep = (valor) => {
    setErrors(errors => ({ ...errors, cep: null }))
    setDados({
      ...dados,
      cep: mascararCEP(valor)
    })
  }

  const handleFocus = (campo) => {
    setTouched({
      ...touched,
      [campo]: true
    })
  }

  const _validate = (campo, ignoreTouched = false) => validate(touched, schema, dados, campo, setErrors, ignoreTouched)

  const handleBlur = (campo) => {
    _validate(campo)
  }

  return (
    <AreaLogada>
      <div className="text-[#1E1E1E]">
        <h1 className='text-[24px] xl:text-[48px] text-center xl:text-start font-bold mb-6'>Dados Cadastrais</h1>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit}
        >
          <div className='grid grid-cols-1 xl:grid-cols-3 gap-x-10 gap-y-5'>
            <div>
              <Input
                id="nome"
                label="Nome"
                className="w-full"
                value={dados.nome || ''}
                onChange={ev => handleChange('nome', ev.target.value)}
                error={errors.nome}
                onBlur={() => handleBlur('nome')}
                onFocus={() => handleFocus('nome')}
              />
            </div>
            <div>
              <Input
                id="sobrenome"
                label="Sobrenome"
                className="w-full"
                value={dados.sobrenome || ''}
                onChange={ev => handleChange('sobrenome', ev.target.value)}
                error={errors.sobrenome}
                onBlur={() => handleBlur('sobrenome')}
                onFocus={() => handleFocus('sobrenome')}
              />
            </div>
            <div>
              <Input
                id="cpf"
                label="CPF"
                className="w-full"
                value={dados.cpf || ''}
                onChange={ev => handleChangeCPF(ev.target.value)}
                error={errors.cpf}
                onBlur={() => handleBlur('cpf')}
                onFocus={() => handleFocus('cpf')}
              />
            </div>
            <div>
              <Input
                id="dataNascimento"
                label="Data de Nascimento"
                className="w-full"
                value={dados.dataNascimento || ''}
                onChange={ev => handleChangeDataNascimento(ev.target.value)}
                error={errors.dataNascimento}
                onBlur={() => handleBlur('dataNascimento')}
                onFocus={() => handleFocus('dataNascimento')}
              />
            </div>
            <div>
              <Input
                id="email"
                label="E-mail"
                className="w-full"
                value={dados.email || ''}
                onChange={ev => handleChange('email', ev.target.value)}
                error={errors.email}
                onBlur={() => handleBlur('email')}
                onFocus={() => handleFocus('email')}
              />
            </div>
            <div>
              <Input
                id="celular"
                label="DDD + Telefone"
                className="w-full"
                value={dados.celular || ''}
                onChange={ev => handleChangeCelular(ev.target.value)}
                error={errors.celular}
                onBlur={() => handleBlur('celular')}
                onFocus={() => handleFocus('celular')}
              />
            </div>
            <div>
              <Input
                id="cep"
                label="Cep"
                className="w-full"
                value={dados.cep || ''}
                onChange={ev => handleChangeCep(ev.target.value)}
                error={errors.cep}
                onBlur={() => handleBlur('cep')}
                onFocus={() => handleFocus('cep')}
              />
            </div>
            <div>
            </div>
            <div>
            </div>
            <div>
              <Input
                id="logradouro"
                label="Endereço"
                className="w-full"
                value={dados.logradouro || ''}
                onChange={ev => handleChange('logradouro', ev.target.value)}
                error={errors.logradouro}
                onBlur={() => handleBlur('logradouro')}
                onFocus={() => handleFocus('logradouro')}
              />
            </div>
            <div>
              <Input
                id="numero"
                label="Número"
                className="w-full"
                value={dados.numero || ''}
                onChange={ev => handleChange('numero', ev.target.value)}
                error={errors.numero}
                onBlur={() => handleBlur('numero')}
                onFocus={() => handleFocus('numero')}
              />
            </div>
            <div>
              <Input
                id="complemento"
                label="Complemento"
                className="w-full"
                value={dados.complemento || ''}
                onChange={ev => handleChange('complemento', ev.target.value)}
                error={errors.complemento}
                onBlur={() => handleBlur('complemento')}
                onFocus={() => handleFocus('complemento')}
              />
            </div>
            <div>
              <Input
                id="bairro"
                label="Bairro"
                className="w-full"
                value={dados.bairro || ''}
                onChange={ev => handleChange(ev.target.value)}
                error={errors.bairro}
                onBlur={() => handleBlur('bairro')}
                onFocus={() => handleFocus('bairro')}
              />
            </div>
            <div>
              <Input
                id="cidade"
                label="Cidade"
                className="w-full"
                value={dados.cidade || ''}
                onChange={ev => handleChange('cidade', ev.target.value)}
                error={errors.cidade}
                onBlur={() => handleBlur('cidade')}
                onFocus={() => handleFocus('cidade')}
              />
            </div>
            <div>
              <Input
                id="estado"
                label="Estado"
                className="w-full"
                value={dados.estado || ''}
                onChange={ev => handleChange('estado', ev.target.value)}
                error={errors.estado}
                onBlur={() => handleBlur('estado')}
                onFocus={() => handleFocus('estado')}
              />
            </div>
          </div>

          <div className='mt-2'>
            <Button
              type="submit"
              className="rounded-md bg-[#7949FF] text-white text-[24px] px-8 py-1 h-[61px] font-semibold w-full xl:w-auto"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </AreaLogada>
  )
}