import { useEffect, useRef, useState } from "react"
import Item from "./Item"

import banner1 from '../../../assets/images/banner-1.png'
import banner1Mobile from '../../../assets/images/banner-1-mobile.png'
import banner2 from '../../../assets/images/banner-2.png'
import banner3 from '../../../assets/images/banner-3.png'

const banners = 3

export default function Banners() {

  return (
    <>
      <div
        className="overflow-hidden h-[300px] xl:hidden"
        style={{
          background: `url(${banner1Mobile})`,
          backgroundPositionX: 'center'
        }}>
      </div>
      <div
        className="overflow-hidden h-[300px] hidden xl:block"
        style={{
          background: `url(${banner1})`,
          backgroundPositionX: 'center'
        }}>
      </div>
    </>
  )
}