import { useState } from "react";
import AreaLogada from "..";
import Select from "../../../components/Select";
import Item from "./Item";

export default function MeuFinanceiro() {
  const [status, setStatus] = useState()

  return (
    <AreaLogada>
      <h1 className="text-3xl font-bold text-center text-verde mb-4">Meu Financeiro</h1>

      <Select
        label="Status"
        options={[
          { value: "", label: "Todos" },
          { value: "NP", label: "Não Processado" },
          { value: "EP", label: "Em Processamento" },
          { value: "EA", label: "Em Aberto" },
          { value: "PF", label: "Processado com Falha" },
          { value: "F", label: "Finalizado" },
          { value: "C", label: "Cancelado" },
        ]}
        value={status}
        onChange={ev => setStatus(ev.target.value)}
      />

      <div className="mt-4 flex flex-col gap-2">
        <Item />
        <Item />
        <Item />
      </div>

    </AreaLogada>
  )
}