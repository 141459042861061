import { useEffect, useState } from "react";
import AreaLogada from "..";
import Select from "../../../components/Select";
import ItemEntrega from "./ItemEntrega";
import Loading from "../../../components/Loading";
import pedidoService from "../../../services/pedidoService";
import ModalPix from "../../../components/ModalPix";
import iconeBusca from '../../../assets/images/icone-busca.png'

export default function MinhasEntregas() {
  const [pedidos, setPedidos] = useState()
  const [configPix, setConfigPix] = useState()

  useEffect(() => {
    carregar()
  }, [])

  const carregar = async () => {
    pedidoService.listar().then(setPedidos)
  }

  if (!pedidos)
    return <Loading />

  return (
    <AreaLogada>
      <div className="text-[#1E1E1E]">
        <h1 className='text-[24px] xl:text-[48px] text-center xl:text-start font-bold mb-6'>Minhas Entregas</h1>

        <div className="flex flex-col xl:flex-row gap-4">
          <div className="w-full xl:w-1/3">
            <div className="border border-[#B8B8B8] px-4 py-2 rounded-full flex flex-row gap-2 items-center h-[49px]">
              <img src={iconeBusca} />
              <input type="text" className="h-[45px] w-full active:border-0 outline-none" />
            </div>
          </div>
          <div className="w-full xl:w-2/3">
            <Select
              options={[
                { value: "", label: "Todos" },
                { value: "EA", label: "Em Aberto" },
                { value: "EAN", label: "Em Análise" },
                { value: "F", label: "Finalizado" },
                { value: "C", label: "Cancelada" },
                { value: "AP", label: "Aguardando Postagem" },
                { value: "P", label: "Postado" },
                { value: "APF", label: "Aguardando Pagamento de frete" },
                { value: "FP", label: "Frete com pagamento finalizado" },
              ]}
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-2">
          {pedidos.map(el => (
            <ItemEntrega
              key={el.id}
              item={el}
              onUpdated={() => carregar()}
            />
          ))}
        </div>
      </div>
      <ModalPix isOpen={!!configPix} config={configPix} onClose={() => setConfigPix()} />
    </AreaLogada>
  )
}