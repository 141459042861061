import { get } from "./service"

class TipoCaixaService {
  async carregar() {
    let url = 'front/tipo-caixa'
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }
}

const tipoCaixaService = new TipoCaixaService()
export default tipoCaixaService