import CustomError from "../infra/Error"
import { post, get, put } from "./service"

class InstituicaoService {
  async listar() {
    let url = 'front/instituicao'
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }
}

const instituicaoService = new InstituicaoService()
export default instituicaoService 
