import { useEffect, useState } from "react"

import iconeFechar from '../assets/images/icone-botao-fechar-branco.png'

import './ModalItem.scss'
import itemService from "../services/itemService"
import Loading from "./Loading"

export default function ModalItem({
  idItem,
  onFechar
}) {
  const [item, setItem] = useState(null)

  useEffect(() => {
    if (!idItem) return

    itemService.recuperar(idItem).then(setItem)
  }, [idItem])

  if (!item)
    return <Loading />

  return (
    <div>
      <div className="fixed inset-0 bg-[#0007] modal-item z-[999]">
        <div className="xl:max-w-screen-xl mx-auto p-2 xl:p-10">
          <div className="xl:mt-[100px] p-4 xl:p-10 rounded rounded-md bg-white text-[#1E1E1E] flex flex-col xl:flex-row gap-4">
            <div className="xl:w-1/5 flex justify-center items-start">
              <img src={item.imagem} alt={item.nome} className='h-[242px]' />
            </div>
            <div className="xl:w-4/5">
              <div className="flex flex-col gap-2 conteudo overflow-auto h-[60vh] pe-2">
                <div className="h-[37px] bg-[#00C968] rounded text-[14px] font-semibold flex items-center py-5 px-6">Características</div>
                <div dangerouslySetInnerHTML={{ __html: item.caracteristicas }}></div>
                <div className="h-[37px] bg-[#00C968] rounded text-[14px] font-semibold flex items-center py-5 px-6">Conteúdo da Caixa</div>
                <div dangerouslySetInnerHTML={{ __html: item.conteudoCaixa }}></div>
              </div>
              <div className="mt-2">
                <button onClick={onFechar} className='h-10 w-full bg-[#ED3B46] xl:w-[228px] py-2 rounded-md flex flex-row gap-3 justify-center items-center font-bold text-[12px] text-white'><img src={iconeFechar} alt="Fechar" />  Fechar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}