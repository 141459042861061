import { get, post, put } from "./service"

class ContatoService {
  async enviar(nome, email, celular, assunto, mensagem) {
    let url = 'front/contato'
    const res = await post(url, {
      nome, email, celular, assunto, mensagem
    })
    if (res.success) {
      return res.data
    }
  }
}

const contatoService = new ContatoService()
export default contatoService