import banner1 from '../../assets/images/banner-1.png'
import banner1Mobile from '../../assets/images/banner-1-mobile.png'

import imgCriarConta from '../../assets/images/como-funciona-criar-conta.png'
import imgEscolherCaixa from '../../assets/images/como-funciona-escolher-caixa.png'
import imgAbrirCaixa from '../../assets/images/como-funciona-abrir-caixa.png'
import imgEnviar from '../../assets/images/como-funciona-envvie.png'



export default function ComoFunciona() {
  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='w-full h-[300px] hidden xl:block' style={{
        background: `url(${banner1})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className='w-full h-[300px] xl:hidden' style={{
        background: `url(${banner1Mobile})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className='bg-white p-4 xl:p-10 flex flex-col items-stretch gap-2 xl:gap-4 w-full text-[#1e1e1e]'>
        <h1 className='text-[24px] xl:text-[48px] font-semibold xl:font-bold text-center xl:text-start'>Como Funciona?</h1>

        <div className='flex flex-col xl:flex-row items-center gap-10'>
          <div className='w-[410px] flex items-center justify-center'><img src={imgCriarConta} alt="Crie uma conta e recarregue seu saldo" className='w-[266px] xl:w-auto' /></div>
          <div className='flex-1'>
            <h2 className='text-[24px] font-semibold text-center xl:text-start'>Crie uma conta <span className='text-[#7949FF]'>e recarregue seu saldo</span></h2>
            <p className='text-[14px] mt-6 text-center xl:text-start'>
              Depois de fazer login na sua conta, você verá o ícone do seu perfil e o saldo no canto superior
              direito do site. Clique no ícone e faça sua recarga. No nosso site você poderá adicionar o saldo pelo PIX.
            </p>
          </div>
        </div>
        <div className='flex flex-col xl:flex-row items-center gap-10'>
          <div className='w-[410px] flex items-center justify-center'><img src={imgEscolherCaixa} alt="Escolha uma caixa" className='w-[266px] xl:w-auto' /></div>
          <div className='flex-1'>
            <h2 className='text-[24px] font-semibold text-center xl:text-start'>Escolha  <span className='text-[#7949FF]'>uma caixa</span></h2>
            <p className='text-[14px] mt-6 text-center xl:text-start'>
              Nosso site oferece diversos modelos de caixas, desde produtos personalizados, artigos de luxo até experiências
              intangíveis Para conhecer todos nossos boxs, vá até a página de listagem de boxes ou clique no botão abaixo.
              Você com certeza ficará impressionado!
            </p>
          </div>
        </div>
        <div className='flex flex-col xl:flex-row items-center gap-10'>
          <div className='w-[410px] flex items-center justify-center'><img src={imgAbrirCaixa} alt="Abra uma caixa" className='w-[266px] xl:w-auto' /></div>
          <div className='flex-1'>
            <h2 className='text-[24px] font-semibold text-center xl:text-start'>Abra <span className='text-[#7949FF]'>uma caixa</span></h2>
            <p className='text-[14px] mt-6 text-center xl:text-start'>
              Com nosso processo de unboxing com um clique, comprar seus produtos favoritos é divertido, fácil e instantâneo.
              Nosso site, garante que cada item que você vê no site tenha um análogo do mundo real e seja 100% autêntico. Cada box
              também contém descrições, preços e probabilidades para cada produto, para que você sempre saiba exatamente o que está
              recebendo. Sem taxas ou pegadinhas escondidas!
            </p>
          </div>
        </div>
        <div className='flex flex-col xl:flex-row items-center gap-10'>
          <div className='w-[410px] flex items-center justify-center'><img src={imgEnviar} alt="Envie ou troque seu item por créditos" className='w-[266px] xl:w-auto' /></div>
          <div className='flex-1'>
            <h2 className='text-[24px] font-semibold text-center xl:text-start'>Envie ou troque seu item <span className='text-[#7949FF]'>por créditos</span></h2>
            <p className='text-[14px] mt-6 text-center xl:text-start'>
              Conseguir um item que você não quer, nunca é um problema no nosso site Se você tirar o item desejado, basta enviar o item
              para o endereço de sua casa e ele estará em suas mãos rapidamente. Se preferir outra coisa, você pode trocá-la por crédito
              em nosso site. Pagamos até 50% do valor deste item no caso de troca.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}