import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { useActions, useAppState } from '../overmind'

import logo from '../assets/images/logo-153.png'
import iconeHome from '../assets/images/icone-home.png'
import iconeComoFunciona from '../assets/images/icone-como-funciona.png'
import iconeCadastreSe from '../assets/images/icone-cadastre-se.png'
import iconeCollab from '../assets/images/icone-collab.png'
import iconeContato from '../assets/images/icone-contato.png'

import iconeDepositar from '../assets/images/icone-depositar.png'
import iconeHistorico from '../assets/images/icone-historico.png'
import iconeDadosCadastrais from '../assets/images/icone-dados-cadastrais.png'
import iconeMeuFinanceiro from '../assets/images/icone-meu-financeiro.png'
import iconeMeusItens from '../assets/images/icone-meus-itens.png'
import iconeMinhasEntregas from '../assets/images/icone-minhas-entregas.png'
import iconeSair from '../assets/images/icone-sair.png'
import iconeMenuVerde from '../assets/images/icone-menu-verde.png'

import iconeHomePreto from '../assets/images/icone-home-preto.png'
import iconeComoFuncionaPreto from '../assets/images/icone-como-funciona-preto.png'
import iconeCadastresePreto from '../assets/images/icone-cadastre-se-preto.png'
import iconeCollabPreto from '../assets/images/icone-collab-preto.png'
import iconeContatoPreto from '../assets/images/icone-contato-preto.png'
import iconeEntrarPreto from '../assets/images/icone-entrar-preto.png'


import Button from "./Button";
import Link from './Link'

import authService from '../services/authService'

const ButtonMenu = ({ onClick, icone, texto }) => (
  <Button
    onClick={() => onClick && onClick()}
    className="rounded-md -mx-[10px] px-[10px] flex flex-row items-center py-[10px] gap-2 hover:bg-gradient-to-r from-[#81FF31] to-[#00C968]"
  >
    <img src={icone} alt={texto} />
    {texto}
  </Button>
)

function Avatar() {
  const { usuario } = useAppState()
  const { setUsuario } = useActions()
  const navigate = useNavigate()
  const avatarRef = useRef()
  const menuRef = useRef()
  const location = useLocation()
  const controleRef = useRef({ visivel: false, clicado: false })

  const handleSair = () => {
    setUsuario(null)
    navigate('/')
  }

  useEffect(() => {
    if (!avatarRef.current || !menuRef.current)
      return
    controleRef.current.visivel = false
    controleRef.current.clicado = false
    menuRef.current.style.display = 'none'
  }, [location.pathname])


  useEffect(() => {
    if (!avatarRef.current || !menuRef.current)
      return

    function over() {
      controleRef.current.visivel = true
      menuRef.current.style.display = 'block'
    }

    function out() {
      controleRef.current.visivel = false
      setTimeout(() => {
        if (!controleRef.current.visivel && !controleRef.current.clicado)
          menuRef.current.style.display = 'none'
      }, 200);
    }

    function click() {
      if (controleRef.current.clicado) {
        menuRef.current.style.display = 'none'
        controleRef.current.clicado = false
      } else {
        menuRef.current.style.display = 'block'
        controleRef.current.clicado = true
      }
    }

    avatarRef.current.addEventListener('mouseover', over)
    avatarRef.current.addEventListener('mouseout', out)
    avatarRef.current.addEventListener('click', click)
    menuRef.current.addEventListener('mouseover', over)
    menuRef.current.addEventListener('mouseout', out)

    return () => {
      if (avatarRef.current) {
        avatarRef.current.removeEventListener('mouseover', over)
        avatarRef.current.removeEventListener('mouseout', out)
        avatarRef.current.removeEventListener('click', click)
      }
      if (menuRef.current) {
        menuRef.current.removeEventListener('mouseover', over)
        menuRef.current.removeEventListener('mouseout', out)
      }
    }
  }, [menuRef, avatarRef])

  const handleButton = url => {
    navigate(url)
  }

  return (
    <div className='xl:relative'>
      <div className='flex gap-2 items-center cursor-default' ref={avatarRef}>
        <div className='text=header text-right font-semibold flex flex-col'>
          <span className="text-verde text-nowrap">{usuario.nome}</span>
          <span className='text-saldo'>R$ {usuario.saldoFmt}</span>
        </div>
        <div>
          <div className='w-[40px] h-[40px] rounded-full bg-[#a2a19c] flex items-center justify-center text-[20px] bg-gradient-to-b from-[#a2a19c] via-[#4f4d46aa] to-[#a2a19c] '>
            {(usuario?.nome || '').split(' ').slice(0, 2).map(el => el.substr(0, 1).toUpperCase()).join('')}
          </div>
        </div>
      </div>
      <div className='absolute bg-[#FFFFFF] xl:rounded-lg p-6 w-full xl:w-[230px] text-[#1E1E1E] left-0 xl:left-auto top-[58px] xl:end-0 xl:top-full z-40' style={{ display: 'none' }} ref={menuRef}>
        <div className='flex justify-between'>
          <div>
            <div className='text-[#444] text-[11px]'>SALDO DA CONTA</div>
            <div className='text-[12px] font-semibold'>R$ {usuario.saldoFmt}</div>
          </div>
          <div className='flex gap-2 items-center xl:hidden'>
            <span className="text-pretoHeader text-nowrap">{usuario.nome}</span>
            <div className='w-[40px] h-[40px] rounded-full bg-[#a2a19c] flex items-center justify-center text-[20px] bg-gradient-to-b from-[#e5e5e2] via-[#ffffff] to-[#e5e5e2] '>
              {(usuario?.nome || '').split(' ').slice(0, 2).map(el => el.substr(0, 1).toUpperCase()).join('')}
            </div>
          </div>
        </div>
        <Button className="bg-verde w-full flex flex-row items-center justify-center gap-2 rounded-md p-2 mt-4 text-[20px]" onClick={() => handleButton('/adicionar-credito')}>
          <img src={iconeDepositar} alt="Depositar" />
          DEPOSITAR
        </Button>
        <div className='flex flex-col mt-2'>
          <ButtonMenu onClick={() => handleButton('/historico')} icone={iconeHistorico} texto="Histórico" />
          <div className='border-t border-[#B8B8B8]'></div>
          <ButtonMenu onClick={() => handleButton('/alterar-cadastro')} icone={iconeDadosCadastrais} texto="Dados Cadastrais" />
          <div className='border-t border-[#B8B8B8]'></div>
          <ButtonMenu onClick={() => handleButton('/alterar-senha')} icone={iconeDadosCadastrais} texto="Alterar Senha" />
          {/* <div className='border-t border-[#B8B8B8]'></div>
          <ButtonMenu onClick={() => handleButton('/historico')} icone={iconeMeuFinanceiro} texto="Meu Financeiro" /> */}
          <div className='border-t border-[#B8B8B8]'></div>
          <ButtonMenu onClick={() => handleButton('/meus-itens')} icone={iconeMeusItens} texto="Meus Itens" />
          <div className='border-t border-[#B8B8B8]'></div>
          <ButtonMenu onClick={() => handleButton('/minhas-entregas')} icone={iconeMinhasEntregas} texto="Minhas Entregas" />
          <div className='border-t border-[#B8B8B8]'></div>
          <ButtonMenu onClick={handleSair} icone={iconeSair} texto="Sair" />
        </div>
      </div>
    </div>
  )
}

const LinkMenu = ({ onClick, icone, texto }) => (
  <Link manterAparencia onClick={onClick} className="flex gap-2 items-center text-header font-semibold"><img src={icone} alt={texto} /> {texto}</Link>
)

const LinkMenuMobile = ({ onClick, icone, texto, ultimo }) => {
  return (
    <Link
      manterAparencia
      onClick={onClick}
      className={"flex gap-3 items-center text-header h-[48px] border-b-[#B8B8B8]" + (!ultimo ? " border-b" : "")}
    >
      <img src={icone} alt={texto} />{texto}
    </Link>
  )
}

const MobileMenu = () => {
  const [aberto, setAberto] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { usuario } = useAppState()

  useEffect(() => {
    setAberto(false)
  }, [location.pathname])

  const toggle = () => {
    setAberto(!aberto)
  }

  return (
    <>
      <div className='xl:hidden'>
        <img src={iconeMenuVerde} alt="Menu" onClick={toggle} />
      </div>
      {aberto && (
        <div className='absolute bg-white text-[#1E1E1E] w-full left-0 top-[58px] p-[27px] z-40'>
          <LinkMenuMobile onClick={() => navigate('/home')} icone={iconeHomePreto} texto="Home" />
          <LinkMenuMobile onClick={() => navigate('/como-funciona')} icone={iconeComoFuncionaPreto} texto="Como funciona" />
          {!usuario && <LinkMenuMobile onClick={() => navigate('/cadastro', { state: { returnUrl: location.pathname } })} icone={iconeCadastresePreto} texto="Cadastre-se" />}
          {/* <LinkMenuMobile onClick={() => navigate('/collab')} icone={iconeCollabPreto} texto="Collab" /> */}
          <LinkMenuMobile onClick={() => navigate('/contato')} icone={iconeContatoPreto} texto="Contato" />
          {!usuario && <LinkMenuMobile onClick={() => navigate('/login', { state: { returnUrl: location.pathname } })} icone={iconeEntrarPreto} texto="Entrar" ultimo />}
        </div>
      )}
    </>
  )
}

export default function DefaultHeader() {
  const location = useLocation()
  const navigate = useNavigate()
  const { usuario } = useAppState()
  const { setUsuario } = useActions()

  useEffect(() => {
    if (usuario) {
      authService.me().catch(() => {
        setUsuario(null)
      })
    }
    // eslint-disable-next-line
  }, [usuario])


  return (
    <header className='bg-pretoHeader'>
      <div className="flex flex-row h-[58px] xl:h-[100px] items-center px-[19px] xl:px-[50px] gap-8">
        <div className='grow xl:grow-0 min-w-[100px]'>
          {location.pathname === '/home' ? (
            <img src={logo} alt="Box da Sorte" className='w-[100px] xl:w-[153px]' />
          ) : (
            <Link onClick={() => navigate('/home')}>
              <img src={logo} alt="Box da Sorte" className='w-[100px] xl:w-[153px]' />
            </Link>
          )}
        </div>
        <div className='flex-1 hidden xl:block'>
          <div className="flex flex-row gap-x-[26px] items-center">
            <LinkMenu onClick={() => navigate('/home')} icone={iconeHome} texto="HOME" />
            <LinkMenu onClick={() => navigate('/como-funciona')} icone={iconeComoFunciona} texto="COMO FUNCIONA" />
            {!usuario && <LinkMenu onClick={() => navigate('/cadastro', { state: { returnUrl: location.pathname } })} icone={iconeCadastreSe} texto="CADASTRE-SE" />}
            {/* <LinkMenu onClick={() => navigate('/collab')} icone={iconeCollab} texto="COLLAB" /> */}
            <LinkMenu onClick={() => navigate('/contato')} icone={iconeContato} texto="CONTATO" />
          </div>
        </div>
        <div>
          {usuario ? (
            <Avatar />
          ) : (
            <Button tipo="entrar" onClick={() => navigate('/login', { state: { returnUrl: location.pathname } })}>ENTRAR</Button>
          )}
        </div>
        <MobileMenu />
      </div>
    </header>
  )
}