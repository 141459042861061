import CustomError from "../infra/Error"
import { post, get, put } from "./service"

class PedidoService {
  async criar(compras) {
    let url = 'front/pedido'
    const res = await post(url, { compras })
    if (res.success) {
      return res.data
    } else {
      throw new CustomError(res.message)
    }
  }

  async listar() {
    let url = 'front/pedido'
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }

  async recuperar(id) {
    let url = 'front/pedido/' + id
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }

  async calcularFrete(aberturas) {
    let url = 'front/pedido/frete'
    const res = await post(url, { compras: aberturas })
    if (res.success) {
      return res.data
    }
  }

  async frete(id) {
    let url = 'front/pedido/' + id + '/frete'
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }

  async freteComCredito(id) {
    let url = 'front/pedido/' + id + '/frete_com_credito'
    const res = await put(url)
    if (res.success) {
      return res.data
    }
  }
}

const pedidoService = new PedidoService()
export default pedidoService 