import { post, get } from "./service"

class HistoricoService {
  async listar(tipo) {
    let url = 'front/historico'
    if (tipo) url += '?tipo=' + tipo
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }
}

const historicoService = new HistoricoService()
export default historicoService 