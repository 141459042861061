import { useNavigate } from "react-router"

export default function Link({ children, onClick, className, manterAparencia, navigateTo, externalHref, newTab }) {
  const navigate = useNavigate()

  if (externalHref)
    return (
      <a
        target={newTab ? "_blank" : "_self"}
        className={className + (!manterAparencia ? ' text-primary underline' : '') + " cursor-pointer"}
        href={externalHref}
        rel={newTab ? "noreferrer" : ""}
      >
        {children}
      </a>
    )

  const handleClick = (ev) => {
    if (navigateTo)
      navigate(navigateTo)
    else if (onClick)
      onClick(ev)
  }

  return (
    <span
      onClick={handleClick}
      className={className + (!manterAparencia ? ' text-primary underline' : '') + " cursor-pointer"}
    >
      {children}
    </span>
  )
}