import { useEffect } from "react"
import { alert } from "../infra/utils"
import socketService from '../services/socketService'
import iconeFechar from '../assets/images/icone-fechar.png'

export default function ModalPix({
  isOpen,
  config,
  onClose
}) {
  useEffect(() => {

    let id
    if (isOpen) {
      id = socketService.assinar({
        update: (evento, dados) => {
          if (evento == 'pix_recebido') {
            if (config.idRequisicao == dados.idRequisicao) {
              alert(`Reconhecemos o recebimento de R$ ${dados.valorPagoFmt} para este PIX.`).then(() => {
                onClose && onClose()
              })
            }
          }
        }
      })
    }

    if (id)
      return () => socketService.cancelarAssinatura(id)

  }, [isOpen])

  if (!isOpen)
    return null

  const copiar = () => {
    window.navigator.clipboard.writeText(config.text).then(() => {
      alert('Código PIX copiado')
    })
  }

  return (
      <div className="fixed inset-0 bg-[#000A] z-40">
        <div className="h-full flex flex-row justify-center items-center p-2">
          <div className="relative bg-[#fff] p-4 rounded-lg text-[#333] w-full xl:w-[600px]">
            <span className="absolute right-2 top-2 w-[24px] h-[24px] flex justify-center items-center cursor-pointer" onClick={() => onClose && onClose()}>
              <img src={iconeFechar} alt="Fechar" />
            </span>

            <h2 className="text-center text-2xl mb-4">PIX</h2>
            <div className="flex flex-col items-center p-4">
              <div>Qr. Code:</div>
              <img src={config.urlPng} className="w-[250px] my-4" alt="QRCode de pagamento do Pix" />
              <div className="w-full flex flex-row">
                <input
                  className="rounded-full rounded-e-none text-[#333] px-2 py-1 w-full border-[#333] border"
                  value={config.text}
                />
                <button
                  className="rounded-full rounded-s-none ps-2 pe-4 py-1 bg-[#DDD] border border-s-0"
                  onClick={copiar}
                >
                  Copiar
                </button>
              </div>
            </div>
            <div>
              <ol className="ml-[20px]" style={{ listStyle: 'decimal' }}>
                <li>Abra o app que você irá realizar o pagamento e escolha pagar via Pix.</li>
                <li>Selecione Pagar QR Code e faça o escaneamento usando seu celular.</li>
                <li>Você também pode copiar e colar o código Pix na opção Pix Copia e Cola.</li>
                <li>Confira as informações e autorize o pagamento.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
  )
}