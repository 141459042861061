import { useMemo } from "react";
import Button from "../../../components/Button";
import { useNavigate } from "react-router";

export default function Item({ item, posicao }) {
  const navigate = useNavigate()

  const corBotao = useMemo(() => {
    switch(posicao) {
      case 0: return 'bg-verde'
      case 1: return 'bg-azul'
      case 2: return 'bg-laranja'
      case 3: return 'bg-rosa'
    }
  }, [posicao])

  return (
    <div className="w-full bg-white rounded-md overflow-hidden flex flex-row">
      <div className="px-2 py-4 flex flex-col gap-1 w-2/3 items-end justify-center">
        <div className="text-black text-[12px] text-right">{item.nomeItem}</div>
        <div className="text-[#78858F] uppercase text-[15px] text-right">{item.nomeCaixa}</div>
        <Button onClick={() => navigate('/caixa/' + item.slug)} className={`${corBotao} rounded-md text-black py-2 px-4 text-[11px] font-bold`}>ABRIR CAIXA R$ {item.valorCaixaFmt}</Button>
      </div>
      <div className="bg-[#f2f2f2] w-1/3 flex justify-center items-center">
        <img src={item.imagem} className="w-[70px]" alt={item.nomeItem} />  
      </div>
    </div>
  )
}