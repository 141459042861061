import { useLocation, useNavigate } from "react-router";

import { useActions } from "../../overmind";

import banner1 from '../../assets/images/banner-1.png'
import banner1Mobile from '../../assets/images/banner-1-mobile.png'
import iconeDepositar from '../../assets/images/icone-depositar-branco.png'

import iconeHistorico from '../../assets/images/icone-historico.png'
import iconeDadosCadastrais from '../../assets/images/icone-dados-cadastrais.png'
import iconeMeuFinanceiro from '../../assets/images/icone-meu-financeiro.png'
import iconeMeusItens from '../../assets/images/icone-meus-itens.png'
import iconeMinhasEntregas from '../../assets/images/icone-minhas-entregas.png'
import iconeSair from '../../assets/images/icone-sair.png'

import iconeHistoricoVerde from '../../assets/images/icone-historico-verde.png'
import iconeDadosCadastraisVerde from '../../assets/images/icone-dados-cadastrais-verde.png'
import iconeMeuFinanceiroVerde from '../../assets/images/icone-meu-financeiro-verde.png'
import iconeMeusItensVerde from '../../assets/images/icone-meus-itens-verde.png'
import iconeMinhasEntregasVerde from '../../assets/images/icone-minhas-entregas-verde.png'
import iconeSairVerde from '../../assets/images/icone-sair-verde.png'
import { useState } from "react";
import classNames from "classnames/bind";



const LinkMenu = ({ children, onClick, icone, iconeHover, active }) => {
  const [hover, setHover] = useState(!!active)

  const className = "py-3 px-4 uppercase cursor-pointer h-[42px] rounded-md flex flex-row gap-2 items-center " +
    (
      active ?
        "bg-gradient-to-r from-[#81FF31] to-[#00C968] text-[#1E1E1E]" :
        "text-[#BCBCBC] hover:bg-gradient-to-r hover:from-[#81FF31] hover:to-[#00C968] hover:text-[#1E1E1E]"
    )

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(!!active)}
    >
      <img src={hover ? iconeHover : icone} />
      {children}
    </div>
  )
}

export default function AreaLogada({ children }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { setUsuario } = useActions()

  const handleSair = () => {
    setUsuario(null)
    navigate('/')
  }

  console.log(location.pathname)

  return (
    <div>
      <div className='w-full h-[300px] hidden xl:block' style={{
        background: `url(${banner1})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className='w-full h-[300px] xl:hidden' style={{
        background: `url(${banner1Mobile})`,
        backgroundPositionX: 'center'
      }}>
      </div>
      <div className="flex flex-row gap-o xl:gap-6 xl:p-10 bg-[#D9D9D9]">
        <div className="w-1/4 flex flex-col xl:gap-10 hidden xl:block">
          <div className="rounded bg-[#2C2C2C] py-8 px-4 h-full">
            <div className="mb-4 px-4">
              <button className="bg-[#FF3B3B] w-full flex flex-row items-center justify-center gap-2 rounded-md p-2 text-[20px]" onClick={() => navigate('/adicionar-credito')}>
                <img src={iconeDepositar} alt="Depositar" />
                DEPOSITAR
              </button>
            </div>
            <div className="flex flex-col gap-2">
              <LinkMenu active={location.pathname == '/historico'} icone={iconeHistoricoVerde} iconeHover={iconeHistorico} onClick={() => navigate('/historico')}>Histórico</LinkMenu>
              <LinkMenu active={location.pathname == '/alterar-cadastro'} icone={iconeDadosCadastraisVerde} iconeHover={iconeDadosCadastrais} onClick={() => navigate('/alterar-cadastro')}>Dados Cadastrais</LinkMenu>
              <LinkMenu active={location.pathname == '/alterar-senha'} icone={iconeDadosCadastraisVerde} iconeHover={iconeDadosCadastrais} onClick={() => navigate('/alterar-senha')}>Alterar Senha</LinkMenu>
              {/* <LinkMenu active={location.pathname == '/meu-financeiro'} icone={iconeMeuFinanceiroVerde} iconeHover={iconeMeuFinanceiro} onClick={() => navigate('/meu-financeiro')}>Meu Financeiro</LinkMenu> */}
              <LinkMenu active={location.pathname == '/meus-itens'} icone={iconeMeusItensVerde} iconeHover={iconeMeusItens} onClick={() => navigate('/meus-itens')}>Meus Itens</LinkMenu>
              <LinkMenu active={location.pathname == '/minhas-entregas'} icone={iconeMinhasEntregasVerde} iconeHover={iconeMinhasEntregas} onClick={() => navigate('/minhas-entregas')}>Minhas Entregas</LinkMenu>
              <LinkMenu icone={iconeSairVerde} iconeHover={iconeSair} onClick={handleSair}>Sair</LinkMenu>
            </div>
          </div>
        </div>
        <div className="w-full xl:w-3/4">
          <div className="bg-white p-6 rounded">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}