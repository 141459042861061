import { createActionsHook, createEffectsHook, createReactionHook, createStateHook } from 'overmind-react'
import { state } from './state'
import * as actions from './actions'

export const useAppState = createStateHook()
export const useActions = createActionsHook()
export const useEffects = createEffectsHook()
export const useReaction = createReactionHook()

export const config = {
  state,
  actions
}