import { useMemo } from "react"

const Input = ({ name, type, value, onChange, className, placeholder, label, id,
  error, onFocus, onBlur, onKeyUp, disabled = false, innerRef, maxLength, manterAparencia = false
}) => {

  const finalClassName = useMemo(() => {
    let _className = className
    if (!manterAparencia) {
      _className = " rounded px-3 text-[#1e1e1e] bg-[#ECE9E9] border border-[#B8B8B8] disabled:text-[#555] h-[49px]"
      if (error) {
        _className += ` border-[#f00]`
      }
    }
    return _className
  }, [className, error, manterAparencia])

  return (
    <div className={"flex flex-col gap-1 w-full "}>
      {label && <label className="text-[14px]" htmlFor={id}>{label}</label>}
      <input
        id={id}
        name={name}
        ref={innerRef}
        type={type || "text"}
        className={finalClassName}
        placeholder={placeholder || label}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        maxLength={maxLength}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        disabled={disabled}
      />
      {error && <div className="text-[14px] text-[#f00]">{error}</div>}
    </div>
  )
}

export default Input