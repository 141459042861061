import { useEffect, useState } from "react";
import Item from "./Item";
import homeService from "../../../services/homeService";

export default function UltimasAberturas() {
  const [items, setItems] = useState([])

  useEffect(() => {
    homeService.ultimasAberturas().then(items => {
      setItems(items)
    })
  }, [])

  if (!items || items.length == 0)
    return <></>

  return (
    <>
      <div className="hidden xl:block">
        <div className="h-[37px] bg-verde text-black font-bold flex items-center justify-center uppercase">
          Confira os prêmios que estão saindo em tempo real
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4 px-6">
          {items.map((el, i) => (
            <Item key={el.id} item={el} posicao={i} />
          ))}
        </div>
      </div>
    </>
  )
}