import CustomError from "../infra/Error"
import { post, get, put } from "./service"

class ItemService {
  async recuperar(id) {
    let url = 'front/item/' + id
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }
}

const itemService = new ItemService()
export default itemService 
