import { useEffect, useState } from "react"
import Loading from "../../components/Loading"
import ItemCaixa from "../../components/ItemCaixa"
import Caixa from "../../components/Caixa"
import { useAppState } from "../../overmind"

import Sorteando from "./Sorteando"

import { useLocation, useNavigate, useParams } from "react-router"
import caixaService from "../../services/caixaService"
import { alert, naoImplantada } from "../../infra/utils"
import ModalPix from "../../components/ModalPix"
import Button from "../../components/Button"
import CaixaPequena from "../home/CaixasHome/CaixaPequena"
import Doar from "./Doar"
import Receber from "./Receber"
import ModalItem from "../../components/ModalItem"
import EscolherInstituicao from "../../components/EscolherInstituicao"

const modoDetalhesCaixa = {
  fechada: 1,
  sorteando: 2,
  confirmarDoacao: 3,
  confirmarReceber: 4,
  escolherInstituicao: 5
}

export default function DetalhesCaixa() {
  const [item, setItem] = useState()
  const [itemSorteado, setItemSorteado] = useState()
  const [tentar, setTentar] = useState()
  const [similarBox, setSimilarBox] = useState()
  const [configPix, setConfigPix] = useState()
  const [modo, setModo] = useState(modoDetalhesCaixa.fechada)
  const { usuario } = useAppState()
  const navigate = useNavigate()
  const location = useLocation()
  const { slug } = useParams()
  const [itemDetalhe, setItemDetalhe] = useState()

  useEffect(() => {
    carregar()
  }, [slug])

  const carregar = () => {
    caixaService.carregar(slug).then(setItem)
    caixaService.semelhantes(slug).then(setSimilarBox)
  }

  const handleAbrir = () => {
    // setItemSorteado({
    //   idAbertura: 54,
    //   idItemSorteado: 35
    // })
    // setModo(modoDetalhesCaixa.confirmarReceber)
    // return

    caixaService.abrir(slug).then(res => {
      if (res.indisponivel) {
        alert('Esta caixa está indisponível').then(() => {
          carregar()
        })
      } else {
        setItemSorteado(res)
        setTentar(false)
        setModo(modoDetalhesCaixa.sorteando)
      }
    }).catch(ex => {
      alert(ex.message)
    })
  }

  const handleAbrirNovamente = () => {
    setItemSorteado()
    setModo(modoDetalhesCaixa.fechada)
    setTimeout(() => {
      handleAbrir()
    }, 500);
  }

  const handleSolicitarConfirmacaoDoacao = async () => {
    setModo(modoDetalhesCaixa.confirmarDoacao)
  }

  const handleSolicitarConfirmacaoReceber = async () => {
    setModo(modoDetalhesCaixa.confirmarReceber)
  }

  const handleEscolherInstituicao = async () => {
    setModo(modoDetalhesCaixa.escolherInstituicao)
  }

  const handleFechar = () => {
    setItemSorteado()
    setModo(modoDetalhesCaixa.fechada)
  }

  const handleTentar = () => {
    caixaService.tentar(slug).then(res => {
      if (res.indisponivel) {
        alert('Esta caixa está indisponível').then(() => {
          carregar()
        })
      } else {
        setItemSorteado(res)
        setTentar(true)
        setModo(modoDetalhesCaixa.sorteando)
      }
    }).catch(ex => {
      alert(ex.message)
    })
  }

  if (!item || !similarBox)
    return <Loading />

  return (
    <div className="">
      <div className="flex flex-row justify-center items-center gap-4">
        <div>
          <img src={item.imagem} className="w-[100px] xl:w-[300px] mx-auto" alt={item.nome} />
        </div>
        <div className="flex flex-col w-[160px] xl:w-auto">
          <div className="uppercase text-[24px] xl:text-[48px] font-bold drop-shadow-md text-vermelho">{item.nome}</div>
          <div className="flex flex-col xl:flex-row gap-1 xl:gap-4">
            {usuario ? (
              <>
                {item.disponivel ? (
                  <>
                    <Button
                      className="bg-vermelho rounded-md px-4 py-2 text-[14px] font-normal hidden xl:block"
                      onClick={handleAbrir}
                    >
                      ABRIR CAIXA POR R${item.valorFmt}
                    </Button>
                    <Button
                      className="bg-vermelho rounded-md px-4 py-2 text-[14px] font-normal w-full xl:hidden"
                      onClick={handleAbrir}
                    >
                      ABRIR CAIXA
                    </Button>
                  </>
                ) : (
                  <div className="flex justify-center items-center">Caixa Indisponível</div>
                )}
                <div className="xl:hidden text-center">
                  APENAS R${item.valorFmt}
                </div>
              </>
            ) : (
              <>
                {item.disponivel ? (
                  <Button
                    className="bg-vermelho rounded-md px-4 py-2 text-[14px] font-normal"
                    onClick={() => navigate('/login', { state: { returnUrl: location.pathname } })}
                  >
                    FAÇA LOGIN PARA ABRIR O BOX
                  </Button>
                ) : (
                  <div className="flex justify-center items-center">Caixa Indisponível</div>
                )}
                <div className="xl:hidden text-center">
                  APENAS R${item.valorFmt}
                </div>
              </>
            )}
            {item.disponivel && (
              <Button onClick={handleTentar} className="border border-vermelho rounded-md px-4 py-2 text-[14px] font-normal text-white">TENTE DE GRAÇA</Button>
            )}
          </div>
        </div>
      </div>


      <div className="px-3 xl:px-10 mt-10">
        <div className="grid grid-cols-1 xl:grid-cols-5 gap-x-3 xl:gap-x-10 gap-y-16 ">
          {item.itens.map(el => (
            <ItemCaixa key={el.key} item={el} onVerInformacoes={id => setItemDetalhe(id)} />
          ))}
        </div>
        {similarBox.length > 0 && (
          <>
            <h2 className="text-verde uppercase text-center xl:text-start text-[24px] xl:text-[48px] mt-16 font-bold">Caixas Semelhantes</h2>

            <div className="grid grid-cols-1 xl:grid-cols-6 gap-4 mt-4">
              {similarBox.map(el => (
                <CaixaPequena key={el.id} item={el} />
              ))}
            </div>
          </>
        )}
      </div>


      {modo == modoDetalhesCaixa.sorteando && (
        <Sorteando
          item={item}
          sorteado={itemSorteado}
          tentar={tentar}
          onClose={handleFechar}
          onConfirmarDoacao={handleSolicitarConfirmacaoDoacao}
          onConfirmarReceber={handleSolicitarConfirmacaoReceber}
          onAbrirNovamente={handleAbrirNovamente}
        />
      )}
      {modo == modoDetalhesCaixa.confirmarDoacao && (
        <Doar
          item={item}
          sorteado={itemSorteado}
          onFechar={handleFechar}
          onEscolherInstituicao={handleEscolherInstituicao}
        />
      )}
      {modo == modoDetalhesCaixa.escolherInstituicao && (
        <EscolherInstituicao
          idAbertura={itemSorteado.idAbertura}
          onFechar={handleFechar}
          onDoacaoExecutada={handleFechar}
          onVoltar={handleSolicitarConfirmacaoDoacao}
        />
      )}
      {modo == modoDetalhesCaixa.confirmarReceber && (
        <Receber
          item={item}
          sorteado={itemSorteado}
          onFechar={handleFechar}
        />
      )}
      {itemDetalhe && <ModalItem idItem={itemDetalhe} onFechar={() => setItemDetalhe()} />}

      <ModalPix isOpen={!!configPix} config={configPix} onClose={() => setConfigPix()} />
    </div>
  )
}