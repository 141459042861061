import { useState } from "react"
import Link from "../../../components/Link"
import EscolherInstituicao from "../../../components/EscolherInstituicao"
import ModalItem from "../../../components/ModalItem"

const modoDoacao = {
  fechado: 1,
  escolherInstituicao: 2,
}

export default function Item({
  item,
  onReload,
  checked,
  onChange,
  onSolicitarEntrega
}) {
  const [doacao, setDoacao] = useState(modoDoacao.fechado)
  const [itemDetalhe, setItemDetalhe] = useState()

  const handleReceber = async () => {
    onSolicitarEntrega(item.id)
  }

  const handleDoar = () => {
    setDoacao(modoDoacao.escolherInstituicao)
  }

  const handleDoacaoExecutada = () => {
    onReload && onReload()
  }

  const handleDetalhes = () => {
    setItemDetalhe(item.item.id)
  }

  return (
    <div className="flex flex-col xl:flex-row border border-[#EBEBEB] rounded px-4 py-6 gap-2 xl:items-center">
      <div className="xl:w-[36px] text-center">
        <input type="checkbox" className="w-[36px]" checked={checked} onChange={ev => onChange && onChange(ev.target.checked)} />
      </div>
      <div className="flex-1">
        <div className="flex flex-col xl:flex-row xl:items-center gap-2">
          <div className="w-full xl:w-1/2">
            <div className="flex flex-row gap-2">
              <div className="w-[90px]">
                <img src={item.item.imagem} className="w-[90px]" alt={item.item.nome} />
              </div>
              <div>
                <div className="font-bold text-[20px]">{item.item.nome}</div>
                <div className="text-[14px]"><strong className="font-bold">Valor:</strong> R${item.item.valorFmt}</div>
                <div><Link manterAparencia className="text-[14px] text-[#3E880F] underline" onClick={handleDetalhes}>Ver Detalhes</Link></div>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/2 text-[15px] drop-shadow-md">
            <div><strong className="font-bold">CÓD. {item.id}</strong></div>
            <div><strong className="font-bold">DATA SORTEIO:</strong> {item.dataFmt}</div>
            <div><strong className="font-bold">VALOR ABERTURA:</strong> R${item.caixa.valorFmt}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col xl:w-[200px] justify-center gap-1 font-bold text-[15px]">
        <div className="xl:text-center font-bold ">
          Aguardando
        </div>
        <button className="bg-[#81FF31] text-[#1E1E1E] rounded-md mx-auto h-[38px] w-full" onClick={handleDoar}>
          Doar para ONG
        </button>
        <button className="bg-[#8000FF] text-white rounded-md mx-auto h-[38px] w-full" onClick={handleReceber}>
          Solicitar entrega
        </button>
      </div>
      {doacao == modoDoacao.escolherInstituicao && (
        <EscolherInstituicao
          idAbertura={item.id}
          ocultarVoltar
          onFechar={() => setDoacao(modoDoacao.fechado)}
          onDoacaoExecutada={handleDoacaoExecutada}
        />
      )}
      {itemDetalhe && <ModalItem idItem={itemDetalhe} onFechar={() => setItemDetalhe()} />}
    </div>

  )
}