
const Valor = ({ item }) => item.valor >= 0 ?
  (<span className="bg-[green] text-[#fff] px-2 py-1 pt-0 rounded-full">R$ {item.valorFmt}</span>) :
  (<span className="bg-[red] text-[#fff] px-2 py-1 pt-0 rounded-full">R$ {item.valorFmt}</span>)


export default function Item({
  item
}) {
  return (
    <div className="flex flex-col xl:flex-row border border-[#EBEBEB] rounded px-4 py-6 gap-2 items-center">
      <div className="w-full xl:w-1/3 p-2 xl:p-4 text-center">
        <div><strong>#{item.id}</strong> - {item.dataFmt}</div>
        <div><strong>{item.descricao}</strong></div>
      </div>
      <div className="w-full xl:w-1/3 p-2 xl:p-4 text-center">
        <div><strong>Tipo:</strong> {item.valor >= 0 ? "Crédito" : "Débito"}</div>
        <div>Valor: <Valor item={item} /></div>
      </div>
      <div className="w-full xl:w-1/3 p-2 xl:p-4 text-center">
        {item.caixa && (
          <>
            <div><strong>Box:</strong> {item.caixa.nome}</div>
            <div><strong>Item:</strong> {item.caixa.item}</div>
          </>
        )}
        {item.pedido && (
          <>
            <div><strong>Encomenda #{item.pedido.id}</strong></div>
            <div><strong>Data:</strong> {item.pedido.dataFmt}</div>
          </>
        )}
      </div>
    </div>
  )
}