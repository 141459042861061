export default function Loading() {
  const raio = 45
  const perimetro = 2 * Math.PI * raio
  const angulo = 290
  const strokeDasharray = `${perimetro * angulo / 360} ${perimetro * (360 - angulo) / 360}`

  return (
    <div className="fixed bg-[#0009] z-50 top-0 left-0 right-0 bottom-0">
      <div className="flex justify-center items-center h-full text-[#ccc]">
        <svg viewBox="0 0 100 100" width={36} className="animate-spin">
          <circle 
            cx={50} cy={50} r={raio} fill="none" stroke="#fff" 
            strokeWidth={10} 
            strokeDashoffset={2}
            strokeDasharray={strokeDasharray}
          />
        </svg>
      </div>
    </div>
  )
}