
export default function QuemSomos() {
  return (
    <div className="container mx-auto my-10 flex flex-col items-center">
      <div className="max-w-[800px] bg-primary5 rounded-md p-4">
        <h1 className="text-2xl font-semibold grow text-primary">Quem somos</h1>

      </div>
    </div>
  )
}