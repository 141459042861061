import CustomError from "../infra/Error"
import { get, post } from "./service"

class CaixaService {
  async carregar(slug) {
    let url = 'front/caixa/' + slug
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }

  async semelhantes(slug) {
    let url = 'front/caixa/' + slug + '/semelhantes'
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }

  async abrir(slug) {
    let url = 'front/caixa/' + slug + '/abrir'
    const res = await post(url)
    if (res.success) {
      return res.data
    } else {
      throw new CustomError(res.message)
    }
  }

  async tentar(slug) {
    let url = 'front/caixa/' + slug + '/tentar'
    const res = await post(url)
    if (res.success) {
      return res.data
    } else {
      throw new CustomError(res.message)
    }
  }
}

const caixaService = new CaixaService()
export default caixaService