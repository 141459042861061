import Swal from "sweetalert2"

const localStoragePrefix = "sortebox:"

export function apenasNumeros(valor) {
  if (!valor)
    return ''

  return valor.toString().replace(/[^\d]/gi, '')
}

export function mascararTelefone(telefone) {
  if (!telefone)
    return ""

  const _telefone = apenasNumeros(telefone).substr(0, 12)
  return _telefone
    .split('')
    .map((c, i) => {
      if (i === 0) return `(${c}`
      if (i === 2) return `) ${c}`
      if (_telefone.length === 10 && i === 6) return `-${c}`
      if (_telefone.length === 11 && i === 7) return `-${c}`

      return c
    })
    .join('')
}

export function mascararData(data) {
  if (!data)
    return ""

  const _data = apenasNumeros(data).substr(0, 8)
  return _data
    .split('')
    .map((c, i) => {
      if (i === 2) return `/${c}`
      if (i === 4) return `/${c}`

      return c
    })
    .join('')
}

export function mascararCpf(cpf) {
  if (!cpf)
    return ""

  const _cpf = apenasNumeros(cpf).substr(0, 11)
  return _cpf
    .split('')
    .map((c, i) => {
      if (i === 3) return `.${c}`
      if (i === 6) return `.${c}`
      if (i === 9) return `-${c}`

      return c
    })
    .join('')
}

export function validarEmail(email) {
  // var exclude=/[^@-.w]|^[_@.-]|[._-]{2}|[@.]{2}|(@)[^@]*1/;
  // var check=/@[w-]+./;
  // var checkend=/.[a-zA-Z]{2,3}$/;
  // if(((email.search(exclude) != -1)||(email.search(check)) == -1)||(email.search(checkend) == -1)){return false;}
  // else {return true;}
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function mascararCEP(cep) {
  if (!cep)
    return ""

  const _cep = apenasNumeros(cep).substr(0, 8)
  return _cep
    .split('')
    .map((c, i) => {
      if (i === 5) return `-${c}`

      return c
    })
    .join('')
}

export function validate(touched, schema, dados, campo, setErrors, ignoreTouched = false) {
  const _touched = !!touched[campo]
  const _rule = schema.find(el => el.campo === campo)

  setErrors(errors => ({ ...errors, [campo]: null }))
  if (!ignoreTouched && !_touched) return true
  if (!validateRequired(_rule, dados, campo, setErrors)) return false
  if (!validateEmail(_rule, dados, campo, setErrors)) return false
  if (!validateTelefone(_rule, dados, campo, setErrors)) return false
  if (!validateCpf(_rule, dados, campo, setErrors)) return false
  if (!validateDate(_rule, dados, campo, setErrors)) return false
  if (!validateCep(_rule, dados, campo, setErrors)) return false
  if (!validatePassword(_rule, dados, campo, setErrors)) return false
  if (_rule.validateFunction && !_rule.validateFunction(dados, campo, setErrors)) return false
  return true
}

function validateRequired(rule, dados, campo, setErrors) {
  const value = dados[campo]
  if (rule.required && !value) {
    setErrors(errors => ({ ...errors, [campo]: '* Este campo é de preenchimento obrigatório' }))
    return false
  }
  return true
}

function validateEmail(rule, dados, campo, setErrors) {
  const value = dados[campo]
  if (rule.type === 'email' && !!value) {
    if (!validarEmail(value)) {
      setErrors(errors => ({ ...errors, [campo]: '* Email inválido' }))
      return false
    }
  }
  return true
}

function validateTelefone(rule, dados, campo, setErrors) {
  const value = dados[campo]
  if (rule.type === 'telefone' && !!value) {
    if (value.length <= 12) {
      setErrors(errors => ({ ...errors, [campo]: '* Telefone inválido' }))
      return false
    }
  }
  return true
}

function validateCpf(rule, dados, campo, setErrors) {
  const value = dados[campo]
  if (rule.type === 'cpf' && !!value) {
    if (!validateCpfNumber(value)) {
      setErrors(errors => ({ ...errors, [campo]: '* CPF inválido' }))
      return false
    }
  }
  return true
}

function validateDate(rule, dados, campo, setErrors) {
  const value = dados[campo]
  if (rule.type === 'date' && !!value) {
    if (!validarDataPtBR(value)) {
      setErrors(errors => ({ ...errors, [campo]: '* Data inválida' }))
      return false
    }
  }
  return true
}

function validateCep(rule, dados, campo, setErrors) {
  const value = dados[campo]
  if (rule.type === 'cep' && !!value) {
    if (value.length < 9) {
      setErrors(errors => ({ ...errors, [campo]: '* CEP inválido' }))
      return false
    }
  }
  return true
}

function validatePassword(rule, dados, campo, setErrors) {
  const value = dados[campo]
  if (rule.type === 'password' && !!value) {
    if (value.length < 6) {
      setErrors(errors => ({ ...errors, [campo]: '* Uma senha precisa ter pelo menos 6 caracteres' }))
      return false
    }
  }
  return true
}

export function getLocalStorage(campo) {
  const s = window.localStorage.getItem(localStoragePrefix + campo)
  return s ? JSON.parse(s) : null
}

export function setLocalStorage(campo, valor) {
  window.localStorage.setItem(localStoragePrefix + campo, valor ? JSON.stringify(valor) : null)
}

export function getSessionStorage(campo) {
  const s = window.sessionStorage.getItem(localStoragePrefix + campo)
  return s ? JSON.parse(s) : null
}

export function setSessionStorage(campo, valor) {
  window.sessionStorage.setItem(localStoragePrefix + campo, valor ? JSON.stringify(valor) : null)
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function () {
      resolve(arrayBufferToBase64(reader.result))
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
      reject()
    };
  })
}

export function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function formatNumber(value, digits = 2) {
  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
}

export function formatDate(value) {
  return value.toLocaleDateString('pt-BR')
}

export function datePtBrToIso(value) {
  const [dia, mes, ano] = value.split('/')
  return `${ano}-${mes}-${dia}T03:00:00.000Z`
}

const normalDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
export function daysInMonth(year, month) {
  if (year % 4 === 0 && month === 2) return 29
  return normalDaysInMonth[month - 1]
}

export function isValidDate(year, month, day) {
  if (year < 1900 || day < 1 || month < 1 || month > 12) return false
  if (day > daysInMonth(year, month)) return false
  return true
}

export function maskDate(value) {
  value = apenasNumeros(value || '')
  return value.substring(0, 8).split('').map((c, i) => {
    if (i === 1) return c + '/'
    if (i === 3) return c + '/'
    return c
  }).join('')
}

export function maskNumber(value, decimals = 0) {
  value = apenasNumeros(value || '')
  return value
    .split('')
    .reverse()
    .map((c, i) => {
      if (decimals > 0 && i === decimals) return `${c},`
      if (i == decimals + 3) return `${c}.`
      if (i == decimals + 6) return `${c}.`
      if (i == decimals + 9) return `${c}.`
      return c
    })
    .reverse()
    .join('')
}

export async function confirm(text, title = null) {
  return await Swal.fire({
    title,
    text,
    showCancelButton: true,
    cancelButtonText: "Não",
    showConfirmButton: true,
    confirmButtonText: "Sim",
  })
}

export async function alert(text, title = null) {
  return await Swal.fire({
    title,
    text,
  })
}

export async function naoImplantada() {
  await alert('Função não implantada')
}

export async function error(text, title = null) {
  return await Swal.fire({
    title,
    icon: "error",
    text,
  })
}

export function validateCpfNumber(cpf) {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999")
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return false;
  return true;
}

export function validarDataPtBR(data) {
  const [_dia, _mes, _ano] = data.split('/')
  const dia = parseInt(_dia)
  const mes = parseInt(_mes)
  const ano = parseInt(_ano)
  if (dia < 1 || mes < 1 || ano < 1 || mes > 12) return false
  const limites = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if (ano % 4 == 0) limites[1] = 29
  if (dia > limites[mes - 1]) return false
  return true
}
