import { useEffect, useState } from "react"

import Loading from "../../components/Loading"
import homeService from "../../services/homeService"
import { useAppState } from "../../overmind"

import Banners from "./Banners"
import UltimasAberturas from "./UltimasAberturas"
import Propaganda from "./Propaganda"
import CaixasHome from "./CaixasHome"

export default function Home() {
  const state = useAppState()
  const [dados, setDados] = useState()
  const [categorias, setCategorias] = useState()

  useEffect(() => {
    homeService.carregar().then(setDados)
    setCategorias([])
  }, [state.localizacao])

  if (!dados && !categorias)
    return <Loading />

  return (
    <>
      <Banners />
      <UltimasAberturas />
      <CaixasHome />
    </>
  )
}