import { useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import Swal from "sweetalert2"


import Input from '../../components/Input'
import Button from "../../components/Button"

import authService from "../../services/authService"

import { useActions, useAppState } from "../../overmind"
import { alert, validate } from "../../infra/utils"
import creditoService from "../../services/creditoService"
import AreaLogada from "../areaLogada"
import { useSearchParams } from "react-router-dom"

export default function AlterarSenha() {
  const [dados, setDados] = useState({})
  const [touched, setTouched] = useState({})
  const [errors, setErrors] = useState({})
  const { usuario } = useAppState()
  const { setUsuario } = useActions()
  const { state } = useLocation()
  const [searchParams] = useSearchParams()
  const { returnUrl } = state || { returnUrl: searchParams.get('returnUrl') }

  const navigate = useNavigate()

  const schema = useMemo(() => {
    return [
      { campo: 'senhaAtual', type: 'password', required: true },
      { campo: 'novaSenha', type: 'password', required: true },
      {
        campo: 'confirmarSenha',
        required: true,
        validateOnChange: true,
        validateFunction: (dados, campo, setErrors) => {
          const value = dados[campo]
          if (dados.novaSenha !== value) {
            setErrors(errors => ({ ...errors, [campo]: '* A nova senha e a confirmação estão diferentes' }))
            return false
          }
          return true
        }
      },
    ]
  }, [])


  const handleChange = (campo, valor) => {
    setErrors(errors => ({ ...errors, [campo]: null }))
    setDados({
      ...dados,
      [campo]: valor
    })
  }

  const handleSubmit = async ev => {
    ev.preventDefault()
    const isValid = schema.reduce((isValid, rule) => {
      if (!_validate(rule.campo, true))
        return false
      return isValid
    }, true)

    if (isValid) {
      if (await authService.alterarSenha(dados.senhaAtual, dados.novaSenha)) {

        const { senhaTemporaria } = usuario

        const authUser = await authService.login(usuario.email, dados.novaSenha)
        setUsuario(authUser)
        const me = await authService.me()
        setUsuario({ ...authUser, ...me })
        alert('Senha alterada!')
        navigate(returnUrl || '/')
      } else {
        Swal.fire({
          text: 'A senha atual informada está incorreta'
        })
      }
    }
  }

  const handleFocus = (campo) => {
    setTouched({
      ...touched,
      [campo]: true
    })
  }

  const _validate = (campo, ignoreTouched = false) => validate(touched, schema, dados, campo, setErrors, ignoreTouched)

  const handleBlur = (campo) => {
    _validate(campo)
  }

  return (
    <AreaLogada>
      <div className="text-[#1E1E1E]">
        <h1 className="text-[24px] xl:text-[48px] text-center xl:text-start font-bold mb-6">Alteração de Senha</h1>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit}
        >
          <div className="mb-5">
            Utilize os campos abaixo para atualizar sua senha.
          </div>
          <div>
            <Input
              type="password"
              id="senhaAtual"
              label="Senha Atual"
              value={dados.senhaAtual || ''}
              onChange={ev => handleChange('senhaAtual', ev.target.value)}
              error={errors.senhaAtual}
              onBlur={() => handleBlur('senhaAtual')}
              onFocus={() => handleFocus('senhaAtual')}
            />
          </div>
          <div>
            <Input
              id="novaSenha"
              type="password"
              label="Nova Senha"
              value={dados.novaSenha || ''}
              onChange={ev => handleChange('novaSenha', ev.target.value)}
              error={errors.novaSenha}
              onBlur={() => handleBlur('novaSenha')}
              onFocus={() => handleFocus('novaSenha')}
            />
          </div>
          <div>
            <Input
              id="confirmarSenha"
              type="password"
              label="Confirme a Nova Senha"
              value={dados.confirmarSenha || ''}
              onChange={ev => handleChange('confirmarSenha', ev.target.value)}
              error={errors.confirmarSenha}
              onBlur={() => handleBlur('confirmarSenha')}
              onFocus={() => handleFocus('confirmarSenha')}
            />
          </div>
          <div className="flex flex-row items-center gap-2 mt-2">
            <Button
              type="submit"
              className="rounded-md bg-[#7949FF] text-white text-[24px] px-8 py-1 h-[61px] font-semibold w-full xl:w-auto"
            >
              Alterar Senha
            </Button>
          </div>
        </form>
      </div>
    </AreaLogada>
  )
}