import { useState } from "react"
import { alert, confirm, naoImplantada } from "../../../infra/utils"

import creditoService from "../../../services/creditoService"
import pedidoService from "../../../services/pedidoService"

import Item from "./Item"
import ModalPix from "../../../components/ModalPix"

export default function ItemEntrega({
  item,
  onUpdated
}) {
  const [configPix, setConfigPix] = useState()

  const handlePagarFrete = async () => {
    let dadosSaldo = await creditoService.saldo()
    if (dadosSaldo.saldo >= item.frete) {
      const res = await confirm(`Você tem R$ ${dadosSaldo.saldoFmt} em sua conta, gostaria de usar para o pagar o frete?`, "Pagamento do frete")
      if (res.isConfirmed) {
        await pedidoService.freteComCredito(item.id)
        await alert('Frete pago com saldo em conta')
        onUpdated && onUpdated()
      }
      else {
        setConfigPix(await pedidoService.frete(item.id))
      }
    } else {
      setConfigPix(await pedidoService.frete(item.id))
    }

  }

  return (
    <div className="flex flex-col border border-[#EBEBEB] rounded px-4 py-6 gap-2">
      <ModalPix isOpen={!!configPix} config={configPix} onClose={() => setConfigPix()} />
      <div className="flex flex-row gap-2">
        <div className="w-1/2">
          <div><strong className="font-bold">PEDIDO {item.id}</strong></div>
          <div><strong className="font-bold">DATA:</strong> {item.dataFmt}</div>
          <div><strong className="font-bold">STATUS:</strong>&nbsp;
            {item.fretePago ? item.statusBling || 'Em Aberto' : item.statusPedido}
          </div>
        </div>
        <div className="w-1/2 text-center">
          <div><strong className="font-bold">FRETE:</strong> R${item.freteFmt}</div>
          {!item.fretePago && (
            <button
              className="bg-[#8000FF] text-white rounded-md h-[38px] w-[200px] mt-2"
              onClick={handlePagarFrete}
            >
              Pagar Frete
            </button>
          )}
        </div>
      </div>
      {item.aberturas.map(el => (
        <Item key={el.id} item={el} />
      ))}
    </div>
  )
}