import { useEffect, useMemo, useState } from 'react'
import Input from '../../../components/Input'
import FilterButton from './FilterButton'
import Caixa from '../../../components/Caixa'

import iconeBuscar from '../../../assets/images/icone-buscar.png'
import homeService from '../../../services/homeService'
import tipoCaixaService from '../../../services/tipoCaixaService'
import Button from '../../../components/Button'
import CaixaGrandeBranca from './CaixaGrandeBranca'
import CaixaGrandePreta from './CaixaGrandePreta'
import CaixaPequena from './CaixaPequena'

const initialFilter = {
  termo: "",
  idTipoLst: []
}

export default function CaixasHome() {
  const [items, setItems] = useState()

  useEffect(() => {
    homeService.carregar().then(setItems)
  }, [])

  if (!items)
    return <></>

  return (
    <div className='mt-4 px-1 xl:px-6 flex flex-col gap-1 md:gap-4'>
      <div className='grid grid-cols-1 xl:grid-cols-2 gap-1 md:gap-4'>
        <CaixaGrandeBranca item={items[0]} />
        <CaixaGrandePreta item={items[1]} />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-1 md:gap-4'>
        {items.splice(2).map((item, i) => (
          <CaixaPequena key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}