import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from "react-datepicker"

import DefaultLayout from './layout/DefaultLayout';

import Loading from './components/Loading';

import socketService from './services/socketService';
import { useActions, useAppState } from './overmind';

import './App.css';

registerLocale('ptBR', ptBR)

function App() {
  const { usuario } = useAppState()
  const { setUsuario } = useActions()

  useEffect(() => {
    let id
    if (usuario) {
      socketService.autenticar(usuario.token)

      id = socketService.assinar({
        update: function (evento, dados) {
          if (evento == 'saldo') {
            setUsuario({ ...usuario, ...dados })
          }
        }
      })
    }

    if (id)
      return () => socketService.cancelarAssinatura(id)
  }, [usuario])

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* <Route exact path="/login" name="Login" element={<Login />} /> */}
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
