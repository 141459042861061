import { useState } from "react"
import Link from "../../../components/Link"
import { naoImplantada } from "../../../infra/utils"

import iconeRelogio from '../../../assets/images/icone-relogio.png'
import ModalItem from "../../../components/ModalItem"

export default function ItemProcessado({
  item,
  onReload
}) {
  const [itemDetalhe, setItemDetalhe] = useState()

  const handleDetalhes = () => {
    console.log(item)
    setItemDetalhe(item.item.id)
  }

  return (
    <div className="flex flex-col xl:flex-row bg-[#EBEBEB] rounded px-4 py-6 gap-2 xl:items-center">
      <div className="xl:w-[36px] text-center">

      </div>
      <div className="flex-1">
        <div className="flex flex-col xl:flex-row xl:items-center gap-2">
          <div className="w-full xl:w-1/2">
            <div className="flex flex-row gap-2">
              <div className="w-[90px]">
                <img src={item.item.imagem} className="w-[90px]" alt={item.item.nome} />
              </div>
              <div>
                <div className="font-bold text-[20px]">{item.item.nome}</div>
                <div className="text-[14px]"><strong className="font-bold">Valor:</strong> R${item.item.valorFmt}</div>
                <div><Link manterAparencia className="text-[14px] text-[#3E880F] underline" onClick={handleDetalhes}>Ver Detalhes</Link></div>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/2 text-[15px] drop-shadow-md">
            <div><strong className="font-bold">CÓD. {item.id}</strong></div>
            <div><strong className="font-bold">DATA SORTEIO:</strong> {item.dataFmt}</div>
            <div><strong className="font-bold">VALOR ABERTURA:</strong> R${item.caixa.valorFmt}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col xl:w-[200px] xl:justify-center gap-1 font-bold text-[15px]">
        <div className="font-bold flex flex-row xl:justify-center items-center gap-2">
          <img src={iconeRelogio} alt="Processando" /> Processando
        </div>
        <div className="bg-[#8000FF] text-white rounded-md mx-auto h-[38px] w-full flex justify-center items-center">
          {item.status}
        </div>
      </div>
      {itemDetalhe && <ModalItem idItem={itemDetalhe} onFechar={() => setItemDetalhe()} />}
    </div>
  )
}