 import { post, put } from "./service"

class CadastroService {
  async criar(nome, email, cpf, celular, senha) {
    let url = 'front/cadastro'
    const res = await post(url, {
      nome, email, cpf, celular, senha
    })
    if (res.success) {
      return res.data
    }
  }

  async alterar(nome, sobrenome, email, cpf, celular, sexo, dataNascimento, cep, logradouro, numero, complemento, bairro, cidade, estado) {
    let url = 'front/cadastro'
    const res = await put(url, {
      nome, sobrenome, email, cpf, celular, sexo, dataNascimento, cep, logradouro, numero, complemento, bairro, cidade, estado
    })
    if (res.success) {
      return res.data
    }
  }

  async alterarEndereco(cep, logradouro, numero, complemento, bairro, cidade, estado) {
    let url = 'front/cadastro/endereco'
    const res = await put(url, {
      cep, logradouro, numero, complemento, bairro, cidade, estado
    })
    if (res.success) {
      return res.data
    }
  }
}

const cadastroService = new CadastroService()
export default cadastroService