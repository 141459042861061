import axios from "axios"
import { getSessionStorage } from "../infra/utils"

export const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

const usuario = getSessionStorage('usuario')
if (usuario) {
  setToken(usuario.token)
}

export function setToken(token) {
  axiosApi.defaults.headers.common["Authorization"] = token ? 'Bearer ' + token : ''
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}