import { get } from "./service"

class HomeService {
  async carregar() {
    let url = 'front/home'
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }

  async ultimasAberturas() {
    let url = 'front/home/ultimas-aberturas'
    const res = await get(url)
    if (res.success) {
      return res.data
    }
  }
}

const homeService = new HomeService()
export default homeService