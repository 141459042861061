import { useMemo } from "react"

export default function Button({ children, onClick, tipo, type = "button", className, disabled }) {
  const customClassName = useMemo(() => {
    if (tipo === 'entrar') {
      //const baseClass = "px-5 py-2 text-header rounded-full text-verde font-semibold uppercase border hover:bg-verde hover:text-pretoHeader ".split(' ')
      const baseClass = "h-[30px] rounded-full text-pretoHeader w-[102px] bg-verde font-semibold uppercase ".split(' ')
      if (className)
        baseClass.push(...className.split(' '))
      return baseClass.join(' ')
    }

    if (tipo === 'newsletter') {
      const baseClass = "px-8 py-2 text-header text-black font-regular bg-gradient-to-r from-[#81FF31] to-[#00C968]".split(' ')
      if (className)
        baseClass.push(...className.split(' '))
      return baseClass.join(' ')
    }

    if (tipo === 'comecar') {
      const baseClass = "px-8 py-3 text-lg bg-verde rounded-full text-pretoHeader font-bold".split(' ')
      if (className)
        baseClass.push(...className.split(' '))
      return baseClass.join(' ')
    }

    const baseClass = "".split(' ')

    // if (disabled) {
    //   baseClass.push("bg-primaryDisabled")
    //   baseClass.push("text-[#ffffff]")
    //   baseClass.push("border-primaryDisabled")
    // } else {
    //   baseClass.push("bg-verde")
    //   baseClass.push("text-pretoItem")
    //   baseClass.push("rounded-full")
    // }

    if (className)
      baseClass.push(...className.split(' '))

    return baseClass.join(' ')

  }, [className, disabled])

  return (
    <button
      className={customClassName}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  )
}